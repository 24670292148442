import {FilterType, FilterValue} from "../../../types/filter";
import {
    CouponCodeBookStatus,
    CouponCodeBookType,
    CouponCodeFilterWordType,
    CouponCodeListQueryType
} from "../../../types/couponcode";
import {useCallback, useMemo, useState} from "react";
import {PageSearchCondition} from "../../../types/page";
import {useFilterValues} from "../../../hooks/list/useFilterValues";
import {useListSearchParams} from "../../../hooks/list/useListSearchParams";

export interface CouponCodeFilterValues{
    searchWordTypeFilterValue: FilterValue<CouponCodeFilterWordType>;
    searchWordFilterValue: FilterValue<string>;
    createdFromFilterValue: FilterValue<Date | undefined>;
    createdToFilterValue: FilterValue<Date | undefined>;
    availableStartAtFilterValue: FilterValue<Date | undefined>;
    availableEndAtFilterValue: FilterValue<Date | undefined>;
    bookTypeFilterValue: FilterValue<CouponCodeBookType>;
    bookStatusFilterValue: FilterValue<CouponCodeBookStatus>;
}
export function useCouponCodeFilterValues() {
    const [searchWordType, setSearchWordType] = useState('');
    const [searchWord, setSearchWord] = useState("");
    const [createdFrom, setCreatedFrom] = useState<Date | undefined>();
    const [createdTo, setCreatedTo] = useState<Date | undefined>();
    const [availableStartAt, setAvailableStartAt] = useState<Date | undefined>();
    const [availableEndAt, setAvailableEndAt] = useState<Date | undefined>();
    const [bookType, setBookType] = useState('');
    const [bookStatus, setBookStatus] = useState('');
    const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

    const {listQueryType, setUrlSearchParams} = useListSearchParams<CouponCodeListQueryType>();

    // 필터 영역
    const filterValueArray: FilterValue<any>[] = useMemo(
        () => [
            {
                key: "searchWordType",
                value: searchWordType,
                setter: setSearchWordType,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            {key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING},
            {
                key: "createdFrom",
                value: createdFrom,
                setter: setCreatedFrom,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "createdTo",
                value: createdTo,
                setter: setCreatedTo,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "availableStartAt",
                value: availableStartAt,
                setter: setAvailableStartAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "availableEndAt",
                value: availableEndAt,
                setter: setAvailableEndAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "bookType",
                value: bookType,
                setter: setBookType,
                defaultValue: '',
                type: FilterType.OTHER,
            },
            {
                key: "bookStatus",
                value: bookStatus,
                setter: setBookStatus,
                defaultValue: '',
                type: FilterType.OTHER,
            },
        ],
        [
            searchWordType, searchWord, createdFrom, createdTo,
            availableStartAt, availableEndAt, bookType, bookStatus]
    );

    const {getFilterValueByKey} = useFilterValues({
        filterValueArray,
        listQueryType: listQueryType,
        setPageInfoOnFilter,
    });

    const filterValues = useMemo(
        () =>
            ({
                searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<CouponCodeFilterWordType>,
                searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
                createdFromFilterValue: getFilterValueByKey("createdFrom") as FilterValue<Date | undefined>,
                createdToFilterValue: getFilterValueByKey("createdTo") as FilterValue<Date | undefined>,
                availableStartAtFilterValue: getFilterValueByKey("availableStartAt") as FilterValue<Date | undefined>,
                availableEndAtFilterValue: getFilterValueByKey("availableEndAt") as FilterValue<Date | undefined>,
                bookTypeFilterValue: getFilterValueByKey("bookType") as FilterValue<CouponCodeBookType>,
                bookStatusFilterValue: getFilterValueByKey("bookStatus") as FilterValue<CouponCodeBookStatus>,
            } as CouponCodeFilterValues),
        [getFilterValueByKey]
    );
    // 필터 영역 종료

    // searchList 영역
    const searchList = useCallback(
        (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: CouponCodeListQueryType) => {
            const param: CouponCodeListQueryType = {
                searchWordType: listQueryTypeParam?.searchWordType,
                searchWord: listQueryTypeParam?.searchWord,
                createdFrom: listQueryTypeParam?.createdFrom,
                createdTo: listQueryTypeParam?.createdTo,
                availableStartAt: listQueryTypeParam?.availableStartAt,
                availableEndAt: listQueryTypeParam?.availableEndAt,
                bookType: listQueryTypeParam?.bookType,
                bookStatus: listQueryTypeParam?.bookStatus,
            };

            return () => setUrlSearchParams(param, pageSearchCondition);
        },
        [setUrlSearchParams]
    );

    return {
        pageInfoOnFilter,
        filterValues, searchList,
        listQueryType
    }
}