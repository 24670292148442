import {ListTable} from "../../../layout/content/list/ListTable";
import {DetailActionButton} from "../../../components/Buttons";
import voucherAddImg from "../../../assets/images/button_icons/add_button.svg";
import voucherDeleteImg from "../../../assets/images/button_icons/delete_icon.svg";
import {ListCount, WhichList} from "../../../components/ListCount";
import React, {useState} from "react";
import {useCouponCodeFilters} from "../hooks/useCouponCodeFilters";
import {useCouponCodeColumns} from "../hooks/useCouponCodeColumns";
import {useCouponCodeGetter} from "../hooks/useCouponCodeGetter";
import {CouponCodeBookDTO, CouponCodeDeleteDTO} from "../../../types/couponcode";
import {useNavigate} from "react-router-dom";
import {useCouponCodeRemover} from "../hooks/useCouponCodeRemover";

export function CouponCodeList() {

    const navigate = useNavigate();
    const {
        couponCodeBookList, totalVoucherCount,
        pageCondition, searchCouponCode, refreshList
    } = useCouponCodeGetter();
    const {removeCouponCode} = useCouponCodeRemover();

    const {filters} = useCouponCodeFilters({search: searchCouponCode({})});
    const {columns} = useCouponCodeColumns({refreshList});
    const [selectedCodeIdx, setSelectedCodeIdx] = useState<number[]>([])

    const showCheckbox = (dto: CouponCodeBookDTO) => {
        return dto.usedCount <= 0;
    }


    const onClickAddCode = () => {
        navigate('/coupon/code-post')
    }

    const onClickRemoveCode = () => {
        if (selectedCodeIdx.length === 0) {
            alert("삭제할 쿠폰코드를 선택해주세요.");
            return;
        }
        if (window.confirm("선택한 쿠폰 코드를 ‘삭제’ 하시겠습니까?\n삭제 시 쿠폰 코드는 되돌릴 수 없으며, 발급된 코드는 모두 사용중지 처리 됩니다")) {
            const dto:CouponCodeDeleteDTO = {
                idxList: selectedCodeIdx
            }
            removeCouponCode(dto).then(() => refreshList());
        }
    }

    return (
        <>
            {couponCodeBookList && (
                <ListTable
                    filters={filters}
                    buttonsNode={
                        <div>
                            <DetailActionButton onClick={() => onClickAddCode()}>
                                <img src={voucherAddImg} alt={"생성 등록"}/>
                                &nbsp;쿠폰 코드 생성
                            </DetailActionButton>
                            <DetailActionButton onClick={() => onClickRemoveCode()}>
                                <img src={voucherDeleteImg} alt={"삭제 아이콘"}/>
                                &nbsp;쿠폰 코드 삭제
                            </DetailActionButton>
                        </div>
                    }
                    listData={couponCodeBookList}
                    checkedRowIdxArr={selectedCodeIdx}
                    setCheckedRowIdxArr={setSelectedCodeIdx}
                    showCheckbox={(c) => showCheckbox(c)}
                    getList={({page, sort, size}) => searchCouponCode({page, sort, size})}
                    columns={columns}
                    totalRowsCnt={{
                        totalCount: <ListCount totalCount={totalVoucherCount ?? 0}
                                               whichList={WhichList.EXTERNAL_VOUCHER}/>,
                        singleUnitKeyInCommonFile: "codeUnit.singleComponent",
                        multipleUnitKeyInCommonFile: "codeUnit.multipleComponent",
                    }}
                    pageOnFilter={pageCondition}
                />
            )}
        </>
    );
}