import {NewWindowOpenerContext, RefreshingParam} from "./NewWindowOpenerContext";
import React, {useCallback, useState} from "react";
import {
    AdminRoleParam,
    AffiliateCodeDurationParam,
    BlockMemberParam,
    CollectProductParam,
    DetailBarInfoCategory,
    GrantCouponParam,
    GrantProductParam,
    MemberUserParam,
    NewWindow,
    OpenNewWindowParam,
    OrganManagerGrantVoucherParam,
    RefundAccountParam,
    WindowMessage,
} from "../../types/newWindow";
import queryString from "query-string";
import {CustomerType} from "../../types/common";
import {MemberUserDetailTabType} from "../../types/user";
import {CODE_KEY} from "../../features/member/organmanager/detail/affiliate_code/OrganManagerAffiliateCode";
import {
    PARTNER_COMPANY_DETAIL_PARAM_ORDERING_NUM
} from "../../features/affiliate_code/partner_company/PartnerCompanyDetail";

export function NewWindowProvider({ children }: { children: React.ReactNode }) {
  const [newWindows, setNewWindows] = useState<Window[]>([]);

  const addAndRemoveEvent = useCallback(
    (child: Window | null, windowMessage: WindowMessage, onRefresh: VoidFunction) => {
      const onEventCallback = (event: MessageEvent) => {
        const { message } = event.data;
        if (message === windowMessage.toString()) onRefresh();
      };
      window.addEventListener("message", onEventCallback, false);

      const timer = window.setInterval(() => {
        if (child?.closed) {
          window.removeEventListener("message", onEventCallback);
          clearInterval(timer);
        }
      }, 1000);
    },
    []
  );

  const openNewWindowWithURL = useCallback(
    (url: string, { idx, param, width, height }: OpenNewWindowParam, refreshParam?: RefreshingParam) => {
      let widthParam = `,width=${width}`;
      let heightParam = `,height=${height}`;

      const newWindow = window.open(
        `${url}${idx ? `/${idx}` : ""}${param ? `?${queryString.stringify(param)}` : ""}`,
        `_blank`,
        `popup=yes${widthParam}${heightParam}`
      );

      if (newWindow) {
        setNewWindows([...newWindows, newWindow]);
      }

      if (refreshParam) {
        addAndRemoveEvent(newWindow, refreshParam.windowMessage, refreshParam.onRefresh);
      }

      return newWindow;
    },
    [newWindows, addAndRemoveEvent]
  );

  const openNewWindow = useCallback(
    (newWindow: NewWindow | string, openNewWindowParam: OpenNewWindowParam): Window | null =>
      openNewWindowWithURL(newWindow.toString(), openNewWindowParam),
    [openNewWindowWithURL]
  );

  const signOutOnChildren = useCallback(() => {
    newWindows.forEach((child) => {
      child.postMessage(
        {
          message: WindowMessage.ADMIN_LOGOUT.toString(),
        },
        "*"
      );
      child.close();
    });
  }, [newWindows]);

  const openUserDetailPopup = useCallback(
    (idx: number, param: MemberUserParam) => {
      openNewWindow(NewWindow.USER_DETAIL, {
        idx,
        param,
        width: 1000,
        height: 800,
      });
    },
    [openNewWindow]
  );

  const openOrganManagerDetailPopup = useCallback(
    (idx: number) => {
      openNewWindow(NewWindow.ORGAN_MANAGER_DETAIL, {
        idx,
        width: 1000,
        height: 800,
      });
    },
    [openNewWindow]
  );

  const openCustomerPopup = useCallback(
    (idx: number, customerType: CustomerType) => {
      if (customerType === CustomerType.USER) {
        openUserDetailPopup(idx, {
          type: MemberUserDetailTabType.SELF_INFO,
          isLeaved: false,
          category: DetailBarInfoCategory.USER,
          name: "",
        });
      } else {
        openOrganManagerDetailPopup(idx);
      }
    },
    [openUserDetailPopup, openOrganManagerDetailPopup]
  );

  const openCreatedProfileDetailPopup = useCallback(
    (idx: number, onRefresh: () => void) => {
      const child = openNewWindow(NewWindow.CREATED_PROFILE_DETAIL, { idx, width: 1000, height: 800 });
      addAndRemoveEvent(child, WindowMessage.CREATED_PROFILE, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openAdminDetailPopup = useCallback(
    (idx: number) => {
      openNewWindow(NewWindow.ADMIN_DETAIL, {
        idx,
        width: 1000,
        height: 800,
      });
    },
    [openNewWindow]
  );

  const openOrderDetailPopup = useCallback(
    (idx: number) => {
      openNewWindow(NewWindow.ORDER_DETAIL, {
        idx,
        width: 1000,
        height: 800,
      });
    },
    [openNewWindow]
  );

  const openBlockMemberPopup = useCallback(
    (param: BlockMemberParam, onRefresh: () => void) => {
      const child = openNewWindow(NewWindow.BLOCK_MEMBER, {
        param,
        width: 1000,
        height: 424,
      });

      addAndRemoveEvent(child, WindowMessage.BLOCK_MEMBER, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openChangingPasswordPopup = useCallback(() => {
    openNewWindow(NewWindow.CHANGING_PASSWORD, {
      width: 1000,
      height: 570,
    });
  }, [openNewWindow]);

  const openAdminRolePopup = useCallback(
    (param: AdminRoleParam, onRefresh: () => void) => {
      const child = openNewWindow(NewWindow.ADMIN_ROLE, {
        param,
        width: 1000,
        height: 390,
      });

      addAndRemoveEvent(child, WindowMessage.ADMIN_ROLE, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openGrantCoupon = useCallback(
    (param: GrantCouponParam, onRefresh?: () => void) => {
      const child = openNewWindow(NewWindow.GRANT_COUPON, {
        param,
        width: 1160,
        height: 710,
      });

      addAndRemoveEvent(child, WindowMessage.GRANT_COUPON, onRefresh ?? (() => {}));
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openGrantProduct = useCallback(
    (param: GrantProductParam, onRefresh?: () => void) => {
      const child = openNewWindow(NewWindow.GRANT_PRODUCT, {
        param,
        width: 1000,
        height: 730,
      });

      addAndRemoveEvent(child, WindowMessage.GRANT_PRODUCT, onRefresh ?? (() => {}));
    },
    [openNewWindow, addAndRemoveEvent]
  );

    const openGroupMemberRegistration = useCallback(
        () => {
            // const child = openNewWindow(NewWindow.GROUP_MEMBER, {
            //     // param,
            //     width: 1000,
            //     height: 730,
            // });

            // addAndRemoveEvent(child, WindowMessage.GRANT_PRODUCT, onRefresh ?? (() => {}));
        },
        //eslint-disable-next-line
        [openNewWindow, addAndRemoveEvent]
    );

  const openUnitItemCreator = useCallback(
    (onRefresh: () => void) => {
      const child = openNewWindow(NewWindow.UNIT_ITEM_CREATOR, {
        width: 1000,
        height: 480,
      });

      addAndRemoveEvent(child, WindowMessage.CREATE_UNIT_ITEM, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openProductDetail = useCallback(
    (idx: number, onRefresh?: () => void) => {
      const child = openNewWindow(NewWindow.PRODUCT_DETAIL, {
        idx,
        width: 1000,
        height: 800,
      });

      if (onRefresh !== undefined) addAndRemoveEvent(child, WindowMessage.UPDATE_PRODUCT, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openCollectProduct = useCallback(
    (param: CollectProductParam, onRefresh: () => void) => {
      const child = openNewWindow(NewWindow.COLLECT_PRODUCT, {
        param,
        width: 1000,
        height: 644,
      });

      addAndRemoveEvent(child, WindowMessage.COLLECT_VOUCHER, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openUnitItemProductsInfo = useCallback(
    (unitItemIdx: number) => {
      openNewWindow(NewWindow.UNIT_ITEM_PRODUCTS.toString().replace(":id", unitItemIdx.toString()), {
        width: 1000,
        height: 800,
      });
    },
    [openNewWindow]
  );

  const openSamplePage = useCallback(
    (param: { name: string; category: DetailBarInfoCategory; otherParam: string }) => {
      openNewWindow(NewWindow.SAMPLE, {
        idx: 101,
        param,
        width: 1000,
        height: 480,
      });
    },
    [openNewWindow]
  );

  const openRefund = useCallback(
    (orderIdx: number, onRefresh: () => void) => {
      const child = openNewWindow(NewWindow.REFUND.toString().replace(":id", orderIdx.toString()), {
        width: 1000,
        height: 800,
      });

      addAndRemoveEvent(child, WindowMessage.REFUNDER, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openRefundAccount = useCallback(
    (param: RefundAccountParam, onRefresh: () => void) => {
      const child = openNewWindow(NewWindow.REFUND_ACCOUNT, {
        param,
        width: 1000,
        height: 656,
      });

      addAndRemoveEvent(child, WindowMessage.REFUND_ACCOUNT, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openOrganManagerGrantVoucher = useCallback(
    (param: OrganManagerGrantVoucherParam, onRefresh?: () => void) => {
      const child = openNewWindow(NewWindow.ORGAN_MANAGER_GRANT_VOUCHER, {
        param,
        width: 1000,
        height: 324,
      });

      if (onRefresh) addAndRemoveEvent(child, WindowMessage.ORGAN_MANAGER_GRANT_VOUCHER, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const openPartnerCompanyDetailPopup = useCallback(
    (idx: number, orderingNumber: number | null, onRefresh?: () => void) => {
      const child = openNewWindow(NewWindow.PARTNER_COMPANY_DETAIL, {
        idx,
        param: {
          [PARTNER_COMPANY_DETAIL_PARAM_ORDERING_NUM]: orderingNumber ?? undefined,
        },
        width: 1000,
        height: 800,
      });

      if (onRefresh !== undefined) addAndRemoveEvent(child, WindowMessage.PARTNER_COMPANY, onRefresh);
    },
    [openNewWindow, addAndRemoveEvent]
  );

  const closeWindowAndNotifyToParentWindow = useCallback((message: WindowMessage) => {
    window.opener?.postMessage(
      {
        message: message.toString(),
      },
      "*"
    );
    window.close();
  }, []);

  const openOrganManagerDetailAffiliateCodeTabPopup = useCallback(
    (organManagerIdx: number, code?: string) => {
      openNewWindowWithURL(
        `/detail/member/organ-manager/${organManagerIdx}?tab=AFFILIATE_CODE${code ? `&${CODE_KEY}=${code}` : ""}`,
        {
          width: 1000,
          height: 800,
        }
      );
    },
    [openNewWindowWithURL]
  );

  const openAffiliateCodeDurationPopup = useCallback(
    (param: AffiliateCodeDurationParam, onRefresh?: () => void) => {
      const child = openNewWindowWithURL(
        `/detail/affiliate-code/${param.codeIdx}?name=${param.organManagerName}&code=${param.code}&startAt=${
          param.startAt
        }${param.endAt ? `&endAt=${param.endAt}` : ""}`,
        {
          width: 1000,
          height: 390,
        }
      );

      if (onRefresh) {
        addAndRemoveEvent(child, WindowMessage.AFFILIATE_CODE_DURATION, onRefresh);
      }
    },
    [openNewWindowWithURL, addAndRemoveEvent]
  );

  const openPopupDetailPopup = useCallback(
    (popupIdx: number, onRefresh?: () => void) => {
      const child = openNewWindowWithURL(`/detail/popup/${popupIdx}`, {
        width: 1000,
        height: 924,
      });

      if (onRefresh) {
        addAndRemoveEvent(child, WindowMessage.POPUP, onRefresh);
      }
    },
    [openNewWindowWithURL, addAndRemoveEvent]
  );

    const openBannerDetail = useCallback(
        (bannerIdx: number, onRefresh?: () => void) => {
            const child = openNewWindowWithURL(`/detail/banner/${bannerIdx}`, {
                width: 1000,
                height: 924,
            });

            if (onRefresh) {
                addAndRemoveEvent(child, WindowMessage.POPUP, onRefresh);
            }
        },
        [openNewWindowWithURL, addAndRemoveEvent]
    );

    const openVoucherInformation = useCallback(
        (idx: number, onRefresh?: () => void) => {
            const child = openNewWindowWithURL(`/detail/external-voucher/${idx}`, {
                width: 1000,
                height: 644,
            });

            if (onRefresh) {
                addAndRemoveEvent(child, WindowMessage.VOUCHER, onRefresh);
            }
        },
        [openNewWindowWithURL, addAndRemoveEvent]
    );

    const openVoucherGeneration = useCallback((onRefresh?: () => void) => {
            const child = openNewWindowWithURL(`/detail/external-voucher/generate`, {
                width: 1000,
                height: 800,
            });

            if (onRefresh) {
                addAndRemoveEvent(child, WindowMessage.VOUCHER, onRefresh);
            }
        },
        [openNewWindowWithURL, addAndRemoveEvent]
    );

    const openVoucherAssignee = useCallback(
        (idx: number, onRefresh?: () => void) => {
            const child = openNewWindowWithURL(`/detail/external-voucher/assign/${idx}`, {
                width: 1000,
                height: 800,
            });

            if (onRefresh) {
                addAndRemoveEvent(child, WindowMessage.VOUCHER, onRefresh);
            }
        },
        [openNewWindowWithURL, addAndRemoveEvent]
    );

    const openCouponCodeDetail = useCallback(
        (idx: number, onRefresh?: () => void) => {
            const child = openNewWindowWithURL(`/detail/coupon-code/${idx}`, {
                width: 1000,
                height: 800,
            });

            if (onRefresh) {
                addAndRemoveEvent(child, WindowMessage.COUPON_CODE, onRefresh);
            }
        },
        [openNewWindowWithURL, addAndRemoveEvent]
    );

    const openCouponCodeModify = useCallback(
        (idx: number, onRefresh?: () => void) => {
            const child = openNewWindowWithURL(`/detail/coupon-code/modifier/${idx}`, {
                width: 1000,
                height: 800,
            });

            if (onRefresh) {
                addAndRemoveEvent(child, WindowMessage.COUPON_CODE, onRefresh);
            }
        },
        [openNewWindowWithURL, addAndRemoveEvent]
    );

  return (
    <NewWindowOpenerContext.Provider
      value={{
        windowArray: newWindows,
        openNewWindowWithURL,
        signOutOnChildren,
        openUserDetailPopup,
        openCustomerPopup,
        openOrganManagerDetailPopup,
        openOrganManagerDetailAffiliateCodeTabPopup,
        openCreatedProfileDetailPopup,
        openAdminDetailPopup,
        openOrderDetailPopup,
        openBlockMemberPopup,
        openChangingPasswordPopup,
        openAdminRolePopup,
        openGrantCoupon,
        openGrantProduct,
        openGroupMemberRegistration,
        openUnitItemCreator,
        openProductDetail,
        openCollectProduct,
        openUnitItemProductsInfo,
        openSamplePage,
        openRefund,
        openRefundAccount,
        openOrganManagerGrantVoucher,
        openAffiliateCodeDurationPopup,
        closeWindowAndNotifyToParentWindow,
        openPartnerCompanyDetailPopup,
        openPopupDetail: openPopupDetailPopup,
        openBannerDetail: openBannerDetail,
        openVoucherInformation,
        openVoucherGeneration: openVoucherGeneration,
        openVoucherAssignee,
        openCouponCodeDetail,
        openCouponCodeModify
      }}
    >
      {children}
    </NewWindowOpenerContext.Provider>
  );
}
