import { TableColumnData } from "../../../../../types/common";
import {
  VoucherHistoryListObj,
  VoucherHistoryListVO,
  VoucherStatus,
  VoucherStatusVO, VoucherType,
} from "../../../../../types/voucher";
import { AnchorComponent } from "../../../../../layout/content/AnchorComponent";
import { RoleChecker } from "../../../../role/RoleChecker";
import { AdminMenuCode } from "../../../../../types/adminRole";
import { RedActionButton } from "../../../../../components/Buttons";
import React, { useCallback, useMemo } from "react";
import { useUserVoucherStatus } from "./useUserVoucherStatus";
import style from "../voucherInfoList.module.scss";
import { useNewWindow, useTimeConverter } from "../../../../../hooks/hooks";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useVoucherType } from "../../../../../types/hooks/useVoucherType";
import {useExternalVoucherConverter} from "../../../../external_voucher/hooks/useExternalVoucherConverter";

interface Args {
  userIdx: number;
  name: string;
  getList: () => void;
}

export function useUserVoucherInfoColumns({ userIdx, name, getList }: Args) {
  const { t } = useTranslation("user");
  const isOutOfDate = useCallback((availableUntil: string) => moment.utc(availableUntil).isBefore(moment()), []);
  const isCollectedVoucher = (status: VoucherStatus) => status === VoucherStatus.COLLECTED;
  const isPurchasedVoucher = (a: VoucherHistoryListObj) => !a.voucherIssuer;
  const { getVoucherStatusInfo } = useUserVoucherStatus({ userIdx });
  const { openOrderDetailPopup, openCollectProduct } = useNewWindow();
  const { getVoucherChannelConverter } = useExternalVoucherConverter();
  const { timeConverter } = useTimeConverter();
  const { toStringFromVoucherStatusVO } = useVoucherType();

  const statusClassName = useMemo(
    () => ({
      [VoucherStatusVO.USABLE.toString()]: "blue",
      [VoucherStatusVO.USED.toString()]: "",
      [VoucherStatusVO.COLLECTED.toString()]: "red",
      [VoucherStatusVO.EXPIRED.toString()]: "red",
    }),
    []
  );

  const columns: TableColumnData<VoucherHistoryListVO>[] = [
    {
      accessor: "unitVoucherType",
      header: t("detail.userVoucherInfo.list.type"),
      sortable: false,
      getNode: (a) => <span>{VoucherType[a.unitVoucherType as keyof typeof VoucherType]}</span>,
    },
    {
      accessor: "createAt",
      header: t("detail.userVoucherInfo.list.createAt"),
      sortable: true,
      getNode: (a) => <span>{timeConverter.convertToLocalTime(a.createAt)}</span>,
    },
    {
      accessor: "availableUntil",
      header: t("detail.userVoucherInfo.list.availableUntil"),
      sortable: true,
      getNode: (a) => <span>{timeConverter.convertToLocalTime(a.availableUntil)}</span>,
    },
    {
      accessor: "voucherIssuer",
      header: t("detail.userVoucherInfo.list.voucherIssuer"),
      sortable: false,
      width: 104,
      getNode: (a) => (
        <span>
          {isPurchasedVoucher(a) ? (
            <AnchorComponent
              onClickFunc={(e) => {
                e.preventDefault();
                if (a.orderIdx) {
                  openOrderDetailPopup(a.orderIdx);
                }
              }}
              content={t("detail.userVoucherInfo.list.payment")}
            />
          ) : (
              // 외부 채널 이용권인 경우
              `${a.externalVoucherIdx 
                  ? getVoucherChannelConverter(a.externalVoucherChannel) 
                  : `${a.voucherIssuer.name}
                  ${isCollectedVoucher(a.unitVoucherStatus) 
                      ? `(${a.voucherCollector.name})` 
                      : ""}`}`
          )}
        </span>
      ),
    },
    {
      accessor: "unitVoucherStatus",
      header: t("detail.userVoucherInfo.list.status"),
      sortable: false,
      getNode: (a) => {
        return (
          <span className={statusClassName[a.voucherVOStatus] ?? ""}>
            {toStringFromVoucherStatusVO(a.voucherVOStatus)}
          </span>
        );
      },
    },
    {
      accessor: "grantReason",
      header: t("detail.userVoucherInfo.list.reason"),
      sortable: false,
      isLeftAlign: true,
      className: style.reasonCell,
      getNode: (a) => (
        <>
          {a.grantReason ? (
            <>
              {a.collectReason ? (
                <>
                  {a.collectReason}
                </>
              ) : (
                <>{a.grantReason}</>
              )}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (a) => {
        if (a.unitVoucherStatus === VoucherStatus.USABLE && !isOutOfDate(a.availableUntil) && !isPurchasedVoucher(a)) {
          return (
            <RoleChecker menuCode={AdminMenuCode.COLLECT_GRANTED_PRODUCT}>
              <RedActionButton
                onClick={() => {
                  openCollectProduct(
                    {
                      userIdx,
                      userName: name,
                      voucherIdx: a.idx,
                    },
                    () => {
                      getList();
                      getVoucherStatusInfo();
                    }
                  );
                }}
              >
                {t("detail.userVoucherInfo.list.collectVoucher")}
              </RedActionButton>
            </RoleChecker>
          );
        }
        return <></>;
      },
    },
  ];

  return { columns };
}
