import {useAppDispatch} from "../../../hooks/hooks";
import {useCallback} from "react";
import {removeCouponCodeStatusAsync} from "../../../store/couponCodeSlice";
import {CouponCodeDeleteDTO} from "../../../types/couponcode";

export function useCouponCodeRemover() {
    const dispatch = useAppDispatch();

    // 쿠폰코드 상태 변경
    const removeCouponCode = useCallback(
        async (dto: CouponCodeDeleteDTO) => {
            try {
                await dispatch(removeCouponCodeStatusAsync(dto)).unwrap();
                alert('쿠폰 코드 삭제되었습니다.')
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    return {
        removeCouponCode,
    }
}