import {CouponDiscountType} from "../../coupon/CouponCreator";
import {CouponCodeValidationProps} from "../../../types/couponcode";

export function useCouponCodeFunctions() {
    const validateCouponCode = (
        {
            eventName,
            generateAmount,
            discountType,
            discountAmount,
            availableStartAt,
            availableEndAt,
            bookType,
            selectedProductIdx
        }: CouponCodeValidationProps) => {
        if (eventName === "") {
            alertAndReturn("쿠폰 코드 명을 입력해 주세요.");
            return false;
        }
        if (generateAmount === 0) {
            alertAndReturn("생성 개수를 입력해 주세요.");
            return false;
        }
        if (discountType === CouponDiscountType.PERCENT && discountAmount === "") {
            alertAndReturn("할인율을 입력해 주세요.");
            return false;
        }
        if (discountType === CouponDiscountType.AMOUNT && discountAmount === "") {
            alertAndReturn("할인금액을 입력해 주세요.");
            return false;
        }
        if (availableStartAt === "" && availableEndAt === "") {
            alertAndReturn("사용 기한을 선택해 주세요.");
            return false;
        }
        if (bookType === "PRODUCT_DISCOUNT" && selectedProductIdx === null) {
            alertAndReturn("적용할 상품을 추가해 주세요.");
            return false;
        }
        return true;
    };

    const alertAndReturn = (message: string) => {
        alert(message);
        return;
    };

    return {
        validateCouponCode, alertAndReturn
    }
}