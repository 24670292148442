import { Menu } from "../../../types/common";
import { AdminMenuCode } from "../../../types/adminRole";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export function useNavMenu() {
  const { t } = useTranslation("nav");

  const menuList: Menu[] = useMemo(
    () => [
      {
        label: t("memberManagement.firstMenu"),
        link: "member",
        subMenu: [
          {
            label: t("memberManagement.submenuUser"),
            link: "user",
            isDisabled: false,
            isSpecial: false,
            menuCode: AdminMenuCode.USER,
          },
          {
            label: t("memberManagement.submenuTester"),
            link: "profiles",
            isDisabled: false,
            isSpecial: false,
            menuCode: AdminMenuCode.TESTERS,
          },
          {
            label: t("memberManagement.submenuSimpleTester"),
            link: "simple-tester",
            isDisabled: false,
            isSpecial: false,
            menuCode: AdminMenuCode.TESTERS,
          },
          {
            label: t("memberManagement.submenuOrganManager"),
            link: "organ-manager",
            isDisabled: false,
            isSpecial: false,
            menuCode: AdminMenuCode.ORGAN_MANAGER,
          },
          {
            label: t("memberManagement.submenuAdmin"),
            link: "admin",
            isDisabled: false,
            isSpecial: false,
            menuCode: AdminMenuCode.ADMIN,
          },
        ],
        isDisabled: false,
        isSpecial: false,
      },
      {
        label: t("kpassInspectionManagement.firstMenu"),
        link: "test",
        subMenu: [
          {
            label: t("kpassInspectionManagement.kpassSubmenuHistory"),
            link: "kpass",
            isDisabled: false,
            isSpecial: false
          },
          {
            label: t("kpassInspectionManagement.dcasSubmenuHistory"),
            link: "dcas",
            isDisabled: false,
            isSpecial: false
          },
        ],
        isDisabled: false,
        isSpecial: false,
        menuCode: AdminMenuCode.KPASS,
      },
      {
        label: t("paymentManagement.firstMenu"),
        link: "order",
        subMenu: [
            { label: t("paymentManagement.submenuHistory"), link: "list", isDisabled: false, isSpecial: false },
            { label: t("paymentManagement.externalVoucher"), link: "external-voucher", isDisabled: false, isSpecial: false }
        ],
        isDisabled: false,
        isSpecial: false,
        menuCode: AdminMenuCode.PAYMENT,
      },
      {
        label: t("productManagement.firstMenu"),
        link: "product",
        subMenu: [
          { label: t("productManagement.submenuList"), link: "list", isDisabled: false, isSpecial: false },
          {
            label: t("productManagement.submenuUnitItemList"),
            link: "unit-item-list",
            isDisabled: false,
            isSpecial: false,
          },
          { label: t("productManagement.submenuCreator"), link: "post", isDisabled: false, isSpecial: false },
        ],
        isDisabled: false,
        isSpecial: false,
        menuCode: AdminMenuCode.PRODUCT,
      },
      {
        label: t("couponManagement.firstMenu"),
        link: "coupon",
        subMenu: [
          { label: t("couponManagement.couponCodeList"), link: "code-list", isDisabled: false, isSpecial: false },
          { label: t("couponManagement.couponCodeCreator"), link: "code-post", isDisabled: false, isSpecial: false },
          { label: t("couponManagement.submenuList"), link: "list", isDisabled: false, isSpecial: false },
          { label: t("couponManagement.submenuCreator"), link: "post", isDisabled: false, isSpecial: false },
        ],
        isDisabled: false,
        isSpecial: false,
        menuCode: AdminMenuCode.COUPON,
      },
      {
        label: "제휴코드관리",
        link: "affiliate-code",
        subMenu: [
          { label: "정산", link: "settlement-list", isDisabled: false, isSpecial: false },
          { label: "제휴코드 리스트", link: "code-list", isDisabled: false, isSpecial: false },
          { label: "제휴코드 등록", link: "post", isDisabled: false, isSpecial: false },
          { label: "협력사 리스트", link: "partner-list", isDisabled: false, isSpecial: false },
          { label: "협력사 등록", link: "partner-post", isDisabled: false, isSpecial: false },
        ],
        isDisabled: false,
        isSpecial: false,
        menuCode: AdminMenuCode.AFFILIATE_CODE,
      },
      {
        label: "카탈로그관리",
        link: "catalog",
        subMenu: [
          {
            label: "팝업 리스트",
            link: "popup-list",
            isDisabled: false,
            isSpecial: false,
          },
          {
            label: "팝업 등록",
            link: "popup-post",
            isDisabled: false,
            isSpecial: false,
          },
          {
            label: "배너 리스트",
            link: "banner-list",
            isDisabled: false,
            isSpecial: false,
          },
          {
            label: "배너 등록",
            link: "banner-post",
            isDisabled: false,
            isSpecial: false,
          },
        ],
        isDisabled: false,
        isSpecial: false,
        menuCode: AdminMenuCode.CATALOG_POP_UP,
      },
    ],
    [t]
  );

  return {
    menuList,
  };
}
