import { DescriptionValue } from "../../../../../layout/content/detail/DescriptionList";
import style from "../../memberUserCoupons.module.scss";
import { Panel } from "../../../../../layout/content/detail/Panel";
import React, { useEffect, useMemo, useRef, useState } from "react";
import questionIcon from "../../../../../assets/images/question_icon.svg";
import { GrantedVoucherHelpModal } from "./GrantedVoucherHelpModal";
import { useUserVoucherStatus } from "../hooks/useUserVoucherStatus";
import { MINI_MODAL_LINK } from "../../../../../util/etcUtil";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../../../hooks/hooks";
import KPASS_LOGO from "../../../../../assets/images/K-PASS_logo_in_tester.svg";
import DCSS_LOGO from "../../../../../assets/images/D-CAS_logo_in_tester.svg";
import {VoucherList} from "../../../../../layout/content/detail/VoucherList";

interface Props {
  userIdx: number;
}

export function VoucherInfoStatus({ userIdx }: Props) {
  const { t } = useTranslation("user");
  const { getVoucherStatusInfo, userVoucherStatusInfo } = useUserVoucherStatus({ userIdx });
  const linkRef = useRef<HTMLButtonElement | null>(null);
  const { isKorean } = useLanguage();

  const FreeVoucherHeader = () => {
    const [showModal, setShowModal] = useState(false);

    return (
      <div className={style.freeVoucherInfo}>
        <span>{t("detail.userVoucherInfo.status.grantedVoucher")}</span>
        <div className={style.helpButtonWrapper}>
          <button
            className={style.helpButton}
            onClick={() => setShowModal(true)}
            ref={linkRef}
            data-name={MINI_MODAL_LINK}
          >
            <img src={questionIcon} alt={t("detail.userVoucherInfo.status.supportIcon")} />
          </button>
          <GrantedVoucherHelpModal
            show={showModal}
            closeModal={() => setShowModal(false)}
            linkElement={linkRef.current ?? undefined}
          />
        </div>
      </div>
    );
  };

  const voucherCntStatus = (
      logo: string, voucherTypeNumber: number
  ) => [
    {
      key: t(""),
      value: <img className={style.logoImg} src={logo} alt={logo}/>,
    },
        {
          key: t("detail.userVoucherInfo.status.paidVoucher"),
          value: t("detail.userVoucherInfo.status.unit",
              {count: userVoucherStatusInfo?.voucherDTOs[voucherTypeNumber]?.purchasedVoucher ?? 0}),
        },
        {
          key: <FreeVoucherHeader/>,
          value: t("detail.userVoucherInfo.status.unit",
              {count: userVoucherStatusInfo?.voucherDTOs[voucherTypeNumber]?.freeVoucher ?? 0}),
        },
        {
          key: t("detail.userVoucherInfo.status.availableVoucher"),
          value: t("detail.userVoucherInfo.status.unit",
              {count: userVoucherStatusInfo?.voucherDTOs[voucherTypeNumber]?.availableVoucher ?? 0}),
        },
        {
          key: t("detail.userVoucherInfo.status.collectedVoucher"),
          value: t("detail.userVoucherInfo.status.unit",
              {count: userVoucherStatusInfo?.voucherDTOs[voucherTypeNumber]?.collectedVoucher ?? 0}),
        },
    ];

  const kpassVoucherStatus: DescriptionValue[][] = useMemo(() => {
    return [voucherCntStatus(KPASS_LOGO, 0)];
    //eslint-disable-next-line
  }, [t, userVoucherStatusInfo]);

  const dcasVoucherStatus: DescriptionValue[][] = useMemo(() => {
    return [voucherCntStatus(DCSS_LOGO, 1)];
    //eslint-disable-next-line
  }, [t, userVoucherStatusInfo]);

  const voucherStatusList: DescriptionValue[][][] = useMemo(() => {
    return [kpassVoucherStatus, dcasVoucherStatus];
  }, [kpassVoucherStatus, dcasVoucherStatus]);

  useEffect(() => {
    if (!userVoucherStatusInfo) {
      getVoucherStatusInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdx, getVoucherStatusInfo]);

    return (
        <Panel title={t("detail.userVoucherInfo.status.title")}>
            {voucherStatusList.map((status, index) => (
                <VoucherList
                    key={index}
                    data={status}
                    labelWidth={isKorean ? 130 : [108, 114, 97, 97]}
                    valueWidth={isKorean ? 100 : 80}
                    valueClassName={style.statusCell}
                />
            ))}
        </Panel>
    );
}
