import { useCallback } from "react";
import { useAppDispatch } from "../../../hooks/hooks";
import { AffiliateCodeOrderSearchCondition, OrderListVO, OrdersDTO, OrderSearchCondition } from "../../../types/orders";
import { PageResponse } from "../../../types/page";
import { searchOrdersAsync } from "../../../store/orderSlice";

export function useOrdersGetter() {
  const dispatch = useAppDispatch();

  const getOrders = useCallback(
    async (condition: OrderSearchCondition | AffiliateCodeOrderSearchCondition): Promise<PageResponse<OrderListVO>> => {
      try {
        const result: PageResponse<OrdersDTO> = await dispatch(searchOrdersAsync(condition)).unwrap();
        return {
          ...result,
          content: result.content.map((it: OrdersDTO) => {
            return {
              idx: it.idx,
              orderTitle: it.orderTitle,
              orderImage: it.orderImage,
              displayPosition: it.displayPosition,
              status: it.status,
              customerType: it.customerType,
              buyerIdx: it.buyerIdx,
              buyerName: it.buyerName,
              paymentWay: it.paymentWay,
              regularPriceAmount: it.regularPriceAmount,
              couponDiscountAmount: it.couponDiscountAmount,
              productDiscountAmount: it.productDiscountAmount,
              payAmount: it.payAmount,
              createAt: it.createAt,
              paidAt: it.paidAt,
              hasMemo: it.hasMemo,
              affiliateCode: it.affiliateCode,
              affiliateCodeIdx: it.affiliateCodeIdx,
              couponCode: it.couponCode,
              couponCodeIdx: it.couponCodeIdx,
              organManagerIdx: it.organManagerIdx,
            } as OrderListVO;
          }),
        };
      } catch (e) {
        console.error(e);
        throw new Error("useMemberOrderGetter - getOrders occurs an error");
      }
    },
    [dispatch]
  );

  return {
    getOrders,
  };
}
