import {useAppDispatch, useListFilterValues, useTimeConverter} from "../../../hooks/hooks";
import {getCouponCodeAsync, getCouponCodeListAsync} from "../../../store/couponCodeSlice";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    CouponCodeBookDTO,
    CouponCodeDetailDTO,
    CouponCodeListQueryType,
    CouponCodeListSearchCondition
} from "../../../types/couponcode";
import {
    DEFAULT_PAGE,
    DEFAULT_SIZE,
    DEFAULT_SORT,
    ordersToSortArr,
    PageResponse,
    PageSearchCondition
} from "../../../types/page";
import {useSortParam} from "../../../hooks/list/useSortParam";
import {dateToString} from "../../../util/dateUtil";

export function useCouponCodeGetter() {
    const dispatch = useAppDispatch();
    const [couponCodeBookList, setCouponCodeBookList] = useState<PageResponse<CouponCodeBookDTO>>()
    const [couponCodeDetail, setCouponCodeDetail] = useState<CouponCodeDetailDTO>()
    const [totalVoucherCount, setTotalVoucherCount] = useState(0)

    // 쿠폰코드 목록 조회 API
    const getCouponCodeList = useCallback(
        async (condition: CouponCodeListSearchCondition, isFirstLoad?: boolean) => {
            try {
                const result: PageResponse<CouponCodeBookDTO> = await dispatch(
                    getCouponCodeListAsync(condition)
                ).unwrap();
                setCouponCodeBookList(result)
                setTotalVoucherCount(result.totalElements);

                if (isFirstLoad) {
                    setTotalVoucherCount(result.totalElements);
                } else {
                    setCouponCodeBookList(result);
                }
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    // 쿠폰코드 목록 조회 API
    const getCouponCode = useCallback(
        async (idx: string) => {
            try {
                const result: CouponCodeDetailDTO = await dispatch(
                    getCouponCodeAsync(idx)
                ).unwrap();
                setCouponCodeDetail(result)

            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
        }, [dispatch]);

    //////
    const {toSortArray} = useSortParam();
    const {timeConverter} = useTimeConverter();
    const {filterValues, searchList, listQueryType} = useListFilterValues() ?? {};

    const {
        searchWordTypeFilterValue,
        searchWordFilterValue,
        createdFromFilterValue,
        createdToFilterValue,
        availableStartAtFilterValue,
        availableEndAtFilterValue,
        bookTypeFilterValue,
        bookStatusFilterValue,
    } = filterValues || {};

    const searchCouponCode = (pageSearchCondition: PageSearchCondition) => {
        const param: CouponCodeListQueryType = {
            searchWordType: searchWordTypeFilterValue.value,
            searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
            createdFrom: createdFromFilterValue.value ? dateToString(createdFromFilterValue.value) : undefined,
            createdTo: createdToFilterValue.value ? dateToString(createdToFilterValue.value) : undefined,
            availableStartAt: availableStartAtFilterValue.value ? dateToString(availableStartAtFilterValue.value) : undefined,
            availableEndAt: availableEndAtFilterValue.value ? dateToString(availableEndAtFilterValue.value) : undefined,
            bookType: bookTypeFilterValue.value ? bookTypeFilterValue.value : undefined,
            bookStatus: bookStatusFilterValue.value ? bookStatusFilterValue.value : undefined,
        };

        return () => {
            searchList(pageSearchCondition, param)();
        }
    };
    ///

    const refreshList = () => {
        getCouponCodeList({}).then();
    };

    const getCouponCodeListWithParam = useCallback(
        (isFirstLoad?: boolean) => {
            if (isFirstLoad) {
                getCouponCodeList({}, true).then();
            }

            const couponCodeListQueryType = listQueryType as CouponCodeListQueryType;
            getCouponCodeList({
                pageCondition: {
                    page: couponCodeListQueryType.page ? Number(couponCodeListQueryType.page) : DEFAULT_PAGE,
                    size: couponCodeListQueryType.size ? Number(couponCodeListQueryType.size) : DEFAULT_SIZE,
                    sort: couponCodeListQueryType.sort ? toSortArray(couponCodeListQueryType.sort) : DEFAULT_SORT,
                },

                query: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
                query_type: searchWordTypeFilterValue.value,
                created_from: couponCodeListQueryType.createdFrom ?
                    timeConverter.convertToFilterStartOfDay(couponCodeListQueryType.createdFrom)
                    : undefined,
                created_to: couponCodeListQueryType.createdTo ?
                    timeConverter.convertToFilterEndOfDay(couponCodeListQueryType.createdTo)
                    : undefined,
                available_start_at: couponCodeListQueryType.availableStartAt ?
                    timeConverter.convertToFilterStartOfDay(couponCodeListQueryType.availableStartAt)
                    : undefined,
                available_end_at: couponCodeListQueryType.availableEndAt ?
                    timeConverter.convertToFilterEndOfDay(couponCodeListQueryType.availableEndAt)
                    : undefined,
                book_type: bookTypeFilterValue.value ? bookTypeFilterValue.value : undefined,
                book_status: bookStatusFilterValue.value ? bookStatusFilterValue.value : undefined,
            }).then();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listQueryType]);

    useEffect(() => {
        if (listQueryType) {
            getCouponCodeListWithParam();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCouponCodeListWithParam]);

    const pageCondition = useMemo(
        () => ({
            page: couponCodeBookList?.number ?? DEFAULT_PAGE,
            size: couponCodeBookList?.size ?? DEFAULT_SIZE,
            sort: ordersToSortArr(couponCodeBookList?.orders),
        }),
        [couponCodeBookList]
    );
    return{
        getCouponCodeList, couponCodeBookList, totalVoucherCount,
        searchCouponCode, pageCondition, refreshList,

        getCouponCode, couponCodeDetail
    }
}