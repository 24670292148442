import {DetailType} from "../../../types/couponcode";
import style from "../style/couponCodeDetail.module.scss";
import {Label104x46} from "../../../components/Label";
import {DurationField} from "../../coupon/creator/DurationField";
import {DetailLabelAndValueField} from "./DetailLabelAndValueField";
import React from "react";
import {useTimeConverter} from "../../../hooks/hooks";

interface Args{
    availableStartAt: string;
    setAvailableStartAt: (_: string) => void;
    availableEndAt: string;
    setAvailableEndAt: (_: string) => void;
    type: DetailType
}
export function UsagePeriodField(
    {availableStartAt, setAvailableStartAt, availableEndAt, setAvailableEndAt, type}:Args) {

    const {timeConverter} = useTimeConverter();
    const availableUntil = `${timeConverter.convertToFilterDateString(new Date(availableStartAt))} ~
        ${timeConverter.convertToFilterDateString(new Date(availableEndAt))}`
    return(
        <>
            {type === DetailType.MODIFY && (
                <div className={style.field}>
                    <Label104x46 text={"사용 기한"}/>
                    <div className={style.addedField}>
                        <DurationField
                            availableStartAt={availableStartAt}
                            availableEndAt={availableEndAt}
                            setAvailableStartAt={(v) => setAvailableStartAt(v)}
                            setAvailableEndAt={(v) => setAvailableEndAt(v)}
                        />
                    </div>
                </div>
            )}
            {type === DetailType.DETAIL && (
                <DetailLabelAndValueField label={"사용 기한"} value={availableUntil}/>
            )}
        </>
    )
}