import {useCallback, useMemo} from "react";
import {CouponCodeBookStatus, CouponCodeBookType, CouponCodeFilterWordType} from "../../../types/couponcode";

export function useCouponCodeConverter() {
    // 검색어 enum 정보 변경 함수
    const couponCodeSearchWordConverter = useMemo(
        () => ({
            [CouponCodeFilterWordType.NAME.toString()]: "쿠폰 코드 명",
        }),
        []
    );
    const getCouponCodeSearchWordConverter = useCallback(
        (status: CouponCodeFilterWordType) => couponCodeSearchWordConverter[status.toString()],
        [couponCodeSearchWordConverter]
    );

    // 쿠폰 코드 유형
    const bookTypeConverter = useMemo(
        () => ({
            [CouponCodeBookType.PRICE_DISCOUNT.toString()]: "결제금액 할인",
            [CouponCodeBookType.PRODUCT_DISCOUNT.toString()]: "특정상품 할인",
        }),
        []
    );
    const getBookTypeConverter = useCallback(
        (status: CouponCodeBookType) => bookTypeConverter[status.toString()],
        //eslint-disable-next-line
        [bookTypeConverter]
    );

    // 상태
    const bookStatusConverter = useMemo(
        () => ({
            [CouponCodeBookStatus.PENDING.toString()]: "사용대기",
            [CouponCodeBookStatus.USABLE.toString()]: "사용중",
            [CouponCodeBookStatus.EXPIRED.toString()]: "기한만료",
            [CouponCodeBookStatus.DISABLED.toString()]: "사용중지",
        }),
        []
    );
    const getBookStatusConverter = useCallback(
        (status: CouponCodeBookStatus) => bookStatusConverter[status.toString()],
        //eslint-disable-next-line
        [bookStatusConverter]
    );

    return {
        getCouponCodeSearchWordConverter,
        getBookTypeConverter,
        getBookStatusConverter
    }
}