import {ListTable} from "../../layout/content/list/ListTable";
import React, {useState} from "react";
import {DetailActionButton} from "../../components/Buttons";
import {ListCount, WhichList} from "../../components/ListCount";
import {useVoucherFilters} from "./hooks/useVoucherFilters";
import {useVoucherColumns} from "./hooks/useVoucherColumns";
import voucherAddImg from "../../assets/images/button_icons/add_button.svg"
import voucherDeleteImg from "../../assets/images/button_icons/delete_icon.svg"
import {useExternalVoucherGetter} from "./hooks/useExternalVoucherGetter";
import {useNewWindow} from "../../hooks/hooks";
import {ExternalVoucherStatus} from "../../types/externalVoucher";
import {useExternalVoucherFunctions} from "./hooks/useExternalVoucherFunctions";

export function ExternalVoucherList() {
    const {voucherList, totalVoucherCount, searchExternalVoucher, refreshList, pageCondition}
        = useExternalVoucherGetter();

    const {filters} = useVoucherFilters({search: searchExternalVoucher({})})
    const [selectedVoucherIdx, setSelectedVoucherIdx] = useState<number[]>([])
    const {columns} = useVoucherColumns({refreshList});
    const { deleteVoucherCode } = useExternalVoucherFunctions();
    const { openVoucherGeneration } = useNewWindow();

    const showCheckbox = (status: ExternalVoucherStatus) =>{
         return (status === ExternalVoucherStatus.ALARMED) || (status === ExternalVoucherStatus.BEFORE_REGISTERED)
    }

    const onClickRemoveVoucher = () => {
        if (selectedVoucherIdx.length === 0) {
            alert("삭제할 이용권 코드를 선택해주세요.");
            return
        }

        if (window.confirm("선택한 이용권을 삭제하시겠습니까?")) {
            deleteVoucherCode({idx: selectedVoucherIdx}).then(() =>{
                alert("삭제가 완료되었습니다.");
                refreshList()
            });
        }
    }

    const onClickAddVoucher = () => {
        openVoucherGeneration()
    }

    return (
        <>
            {voucherList && (
                <ListTable
                    filters={filters}
                    buttonsNode={
                        <div>
                            <DetailActionButton onClick={() => onClickAddVoucher()}>
                                <img src={voucherAddImg} alt={"생성 등록"}/>
                                &nbsp;이용권 코드 생성
                            </DetailActionButton>
                            <DetailActionButton onClick={() => onClickRemoveVoucher()}>
                                <img src={voucherDeleteImg} alt={"삭제 아이콘"}/>
                                &nbsp;이용권 코드 삭제
                            </DetailActionButton>
                        </div>
                    }
                    listData={voucherList}
                    checkedRowIdxArr={selectedVoucherIdx}
                    setCheckedRowIdxArr={setSelectedVoucherIdx}
                    showCheckbox={(c) => showCheckbox(c.externalVoucherStatus)}
                    getList={({page, sort, size}) => searchExternalVoucher({page, sort, size})}
                    columns={columns}
                    totalRowsCnt={{
                        totalCount: <ListCount totalCount={totalVoucherCount ?? 0} whichList={WhichList.EXTERNAL_VOUCHER}/>,
                        singleUnitKeyInCommonFile: "codeUnit.singleComponent",
                        multipleUnitKeyInCommonFile: "codeUnit.multipleComponent",
                    }}
                    pageOnFilter={pageCondition}
                />
            )}
        </>
    );
}