import {useAppDispatch} from "../../../hooks/hooks";
import {useCallback} from "react";
import {getCouponCodeExcelDownloadAsync} from "../../../store/couponCodeSlice";
import {useDownloadFile} from "../../../hooks/common/useDownloadFile";
import {useTranslation} from "react-i18next";
import {callAsync} from "../../../util/sliceUtil";

export function useCouponCodeExcelDownload() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation("couponCode");
    const {getDownloadExcelFile} = useDownloadFile();

    // 쿠폰코드 엑셀 다운로더
    const getCouponCodeExcelDownload = useCallback(
        async (idx: number) => {
            try {
                callAsync(
                    dispatch(getCouponCodeExcelDownloadAsync(idx)).unwrap(),
                    (result) => getDownloadExcelFile(result, t("downloadFileName")),
                    () => alert("일시적인 문제가 발생했습니다.\n다시 시도해주세요.")
                ).then()
            } catch
                (e) {
                alert('일시적인 문제가 발생했습니다.\n다시 시도해주세요.')
            }
            //eslint-disable-next-line
        }, [dispatch]);

    return {
        getCouponCodeExcelDownload,

    }
}