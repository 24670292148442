import {Languages, TableColumnData} from "../../../types/common";
import {ProductListObj, ProductStatus} from "../../../types/product";
import {numberWithCommas} from "../../../util/etcUtil";
import {useProductType} from "../../../types/hooks/useProductType";
import listStyle from "../../../layout/content/list/list.module.scss";
import styled from "styled-components";
import {useTimeConverter} from "../../../hooks/hooks";

const ProductNameWrapperStyle = styled.div`
    display: flex;
    padding: 12px 10px;
    align-items: center;
    gap: 10px;
`;

const ProductNameStyle = styled.div`
    display: flex;
    align-items: center;
    max-width: 170px;
`;

const ThumbnailStyle = styled.img`
    width: 50px;
    height: 50px;
`;

export function useCouponCodeDetailProductColumns() {
    const {toStringFromDisplayPosition, toStringFromLanguage, toStringFromProductStatus} = useProductType();
    const {timeConverter} = useTimeConverter();

    const handlePriceUnit = (language: Languages) => {
        switch (language) {
            case Languages.KO:
                return "원";
            case Languages.EN:
                return "달러";
            default:
                return "원";
        }

    };

    const changeTextColorByStatus = (status: ProductStatus) => {
        switch (status) {
            case ProductStatus.IS_SELLING:
                return listStyle.blue;
            case ProductStatus.STOP_SELLING:
                return listStyle.deleted;
        }
    }

    const columns: TableColumnData<ProductListObj>[] = [
        {
            accessor: "displayPosition",
            header: "진열",
            sortable: false,
            getNode: (it) => <>{toStringFromDisplayPosition(it.displayPosition)}</>,
        },
        {
            accessor: "language",
            header: "상품 언어",
            sortable: false,
            getNode: (it) => <span>{toStringFromLanguage(it.language)}</span>,
        },
        {
            accessor: "name",
            header: "상품명",
            sortable: false,
            getNode: (it) => <ProductNameWrapperStyle>
                <ThumbnailStyle src={it.thumbnailUrl} alt={"productThumbnail"}/>
                <ProductNameStyle>{it.name}</ProductNameStyle>
            </ProductNameWrapperStyle>,
        },
        {
            accessor: "sellingStartDate",
            header: "판매기한",
            sortable: false,
            getNode: (it) =>
                <span>{`${timeConverter.convertToLocalDate(it.sellingStartDate)}
                 ~ ${timeConverter.convertToLocalDate(it.sellingEndDate) === "" 
                    ? "상시판매" 
                    : timeConverter.convertToLocalDate(it.sellingEndDate)
                }`}
                </span>,
        },
        {
            accessor: "productItemCount",
            header: "구성품목",
            sortable: false,
            getNode: (it) => <span>{it.productItemCount}</span>,
        },
        {
            accessor: "status",
            header: "상태",
            sortable: false,
            getNode: (it) => <span className={changeTextColorByStatus(it.status)}>
                {toStringFromProductStatus(it.status)}</span>,
        },
        {
            accessor: "regularPrice",
            header: "정가",
            sortable: false,
            getNode: (it) =>
                <span>{`${numberWithCommas(it.regularPrice)} ${handlePriceUnit(it.language)}`}</span>,
        },
        {
            accessor: "sellingPrice",
            header: "판매가",
            sortable: false,
            getNode: (it) =>
                <span>{`${numberWithCommas(it.sellingPrice)} ${handlePriceUnit(it.language)}`}</span>,
        },
    ];

    return {
        columns,
    };
}
