import {DetailType} from "../../../types/couponcode";
import {DetailLabelAndInputField} from "./DetailLabelAndInputField";
import {DetailLabelAndValueField} from "./DetailLabelAndValueField";
import React from "react";

interface Args{
    type: DetailType;
    value: string;
    setValue: (_: string) => void
}
export function EventNameField({type, value, setValue}:Args) {
    return(
        <>
            {type === DetailType.MODIFY && (
                <DetailLabelAndInputField label={"쿠폰 코드 명"} setValue={setValue}
                                          value={value}/>
            )}
            {type === DetailType.DETAIL && (
                <DetailLabelAndValueField label={"쿠폰 코드 명"}
                                          value={value}/>
            )}
        </>
    )
}