import {Panel} from "../../../layout/content/detail/Panel";
import style from "../style/couponCodeDetail.module.scss"
import {PanelWrapper} from "../../../layout/content/detail/PanelWrapper";
import React, {useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {CouponCodeBookDetail} from "./CouponCodeBookDetail";
import {CouponCodeDetail} from "./CouponCodeDetail";
import {useCouponCodeGetter} from "../hooks/useCouponCodeGetter";
import {HrLine} from "../../../layout/content/post/HrLine";
import {DetailType} from "../../../types/couponcode";
import {useCouponCodeExcelDownload} from "../hooks/useCouponCodeExcelDownload";
import excelIcon from "../../../assets/images/button_icons/excel_icon.svg";

export function CouponCodeDetailWrapper() {
    const {id} = useParams<"id">();
    const formRef = useRef<HTMLFormElement>(null);

    const {getCouponCode, couponCodeDetail} = useCouponCodeGetter();
    const {getCouponCodeExcelDownload} = useCouponCodeExcelDownload();
    useEffect(() => {
        id &&
        getCouponCode(id).then();
        //eslint-disable-next-line
    }, [id]);

    const handleExelDownloadButton = () => {
        getCouponCodeExcelDownload(Number(id)).then(() => console.log("success"));
    }

    return (
        <PanelWrapper>
            {couponCodeDetail && id && (
                <>
                    <Panel title={"쿠폰 코드 상세정보"}>
                        <form className={style.form} ref={formRef} onSubmit={(e) => e.preventDefault()}>
                            <CouponCodeBookDetail couponCodeDetail={couponCodeDetail}
                                                  type={DetailType.DETAIL}/>
                        </form>
                    </Panel>

                    <Panel title={"쿠폰 코드 상세 리스트"} subtitle={`(사용개수 ${couponCodeDetail.usedAmount} 개)`}
                           additionalButtons={
                               <div className={style.buttonsWrapper}>
                                   <button onClick={() => handleExelDownloadButton()} className={style.actionButton}>
                                       <img src={excelIcon} alt={"엑셀 다운로드"} className={style.excelButton}/>
                                       <span className={style.text}>{"엑셀 다운로드"}</span>
                                   </button>
                               </div>
                    }
                    >
                        <form className={style.form} ref={formRef} onSubmit={(e) => e.preventDefault()}>
                            <HrLine/>
                            <CouponCodeDetail couponCodeList={couponCodeDetail.couponCodeList}/>
                        </form>
                    </Panel>
                </>
            )}
        </PanelWrapper>
    );
}