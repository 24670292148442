import style from "../style/couponCodeDetail.module.scss";
import {Label104x46} from "../../../components/Label";
import React from "react";

interface Args {
    label: string;
    value: string;
    setValue: (_: string) => void
}

export function DetailLabelAndInputField({label, value, setValue}: Args) {
    return (
        <div className={style.field}>
            <Label104x46 className={style.label} text={label}/>
            <input
                className={style.input}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={"쿠폰 코드 명을 입력해주세요."}
            />
        </div>
    );
}