import {ListQueryType} from "./list";
import {SearchCondition} from "./page";
import {CouponDiscountType} from "../features/coupon/CouponCreator";
import {ProductListObj} from "./product";

export enum CouponCodeFilterWordType {
    NAME= "NAME"
}

export enum CouponCodeBookType {
    PRICE_DISCOUNT= "PRICE_DISCOUNT",
    PRODUCT_DISCOUNT = "PRODUCT_DISCOUNT"
}

export enum CouponCodeBookStatus {
    PENDING = "PENDING",
    USABLE = "USABLE",
    DISABLED = "DISABLED",
    EXPIRED = "EXPIRED",
}
export enum ManagementStatus{
    USABLE = "USABLE",
    DISABLED = "DISABLED",
}

// 검색 요청 DTO
export interface CouponCodeListQueryType extends ListQueryType {
    searchWordType?: string;
    searchWord?: string;
    createdFrom?: string;
    createdTo?: string;
    availableStartAt?: string;
    availableEndAt?: string;
    bookType?: string;
    bookStatus?: string;
}

export interface CouponCodeListSearchCondition extends SearchCondition {
    query?: string;
    query_type?: CouponCodeFilterWordType;
    created_from?: Date;
    created_to?: Date;
    available_start_at?: Date;
    available_end_at?: Date;
    book_type?: CouponCodeBookType;
    book_status?: CouponCodeBookStatus;
}

export interface CouponCodeBookDTO {
    idx: number;
    eventName: string;
    createAt: string;
    generateAmount: number;
    usedCount: number;
    availableStartAt: string;
    availableEndAt: string;
    status: CouponCodeBookStatus;
    managementStatus: ManagementStatus;
}

export interface CouponCodePostDTO{
    eventName: String,
    generateAmount: number,
    availableStartAt: string,
    availableEndAt: string,
    discountType: CouponDiscountType,
    discountAmount: string,
    applicableProductIdx: number | null,
}

export interface CouponCodeValidationProps{
    eventName : string,
    generateAmount: number,
    discountType: CouponDiscountType,
    discountAmount : string,
    availableStartAt: string,
    availableEndAt: string,
    bookType: CouponCodeBookType,
    selectedProductIdx: number | null,
}

export interface CouponCodeDetailDTO {
    bookIdx: number;
    eventName: string;
    generateAmount: number;
    usedAmount: number;
    bookBenefitType: CouponCodeBookType
    productListDTO: ProductListObj;
    discountType: CouponDiscountType;
    discountAmount: number;
    availableStartAt: Date;
    availableEndAt: Date;
    couponCodeList: CouponCodeVO[];
}

export interface CouponCodePutDTO {
    idx: number;
    eventName: String;
    availableStartAt: string;
    availableEndAt: string;
}

export interface CouponCodeDeleteDTO{
    idxList: number[];
}

export interface CouponCodeVO{
    idx: number;
    usedByUserIdx: number | null;
    usedByUserName: string | null;
    usedByUserId: string | null;
    code: string;
}

export enum DetailType { // 상세페이지에서 컴포넌트 상태를 구분하기 위함
    DETAIL = "DETAIL",
    MODIFY = "MODIFY",
}