import {FilterNumber} from "../../layout/content/list/filter/FilterNumber";
import {FilterContentType} from "../../types/tableFilter";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useLanguage} from "../../hooks/hooks";
import style from "./discount.module.scss";

interface Props {
  point: string;
  setPoint: (v: string) => void;
  label?: string;
}

export function PointField({ point, setPoint, label }: Props) {
  const { t } = useTranslation(["common"]);
  const { isKorean } = useLanguage();
  const MAX_BENEFIT_AMOUNT = 999999;

  useEffect(() => {
    setPoint("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FilterNumber
        className={`${style.discountNumberField} ${isKorean ? "" : style.notKO}`}
        numberField={{
          type: FilterContentType.NUMBER,
          value: point,
          placeholderVal: t("inputNumberPlaceholder"),
          onChangeFunc: (v) => {
              const value = Math.min(Number(v), MAX_BENEFIT_AMOUNT);

              setPoint(value.toString())
          },
          isRequired: true,
        }}
      />
      {label ?? t("discount.amount")}
    </>
  );
}
