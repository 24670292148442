import style from "../../../layout/content/post/creator.module.scss";
import {Label104x46} from "../../../components/Label";
import {CouponCommonNameField} from "../../coupon/creator/CouponCommonNameField";
import {Post} from "../../../layout/content/post/Post";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CouponCodeBookType, CouponCodePostDTO} from "../../../types/couponcode";
import {
    generateCouponCodesAsync,
    isCouponCodeDataSavedState,
    setIsCouponCodeDataSaved
} from "../../../store/couponCodeSlice";
import {useAppDispatch, useAppSelector, useTimeConverter} from "../../../hooks/hooks";
import {CouponTypeCommonRadioField} from "../../coupon/creator/CouponTypeCommonRadioField";
import {Radio} from "../../../components/RadioGroup";
import {CouponType} from "../../../types/coupon";
import {CouponNumberInputField} from "../CouponNumberInputField";
import {HrLine} from "../../../layout/content/post/HrLine";
import {DiscountTypeRadioField} from "../../coupon/creator/DiscountTypeRadioField";
import {PercentField} from "../../../components/discount/PercentField";
import {PointField} from "../../../components/discount/PointField";
import {CouponDiscountType} from "../../coupon/CouponCreator";
import {DurationField} from "../../coupon/creator/DurationField";
import {CouponCreatorProductList} from "../../coupon/creator/CouponCreatorProductList";
import {useCouponCodeFunctions} from "../hooks/useCouponCodeFunctions";
import {useNavigate} from "react-router-dom";
import {useCallbackPrompt} from "../../../hooks/navigationblocker/useCallbackPrompt";
import {callAsync} from "../../../util/sliceUtil";

export function CouponCodeCreator() {
    const {t} = useTranslation("couponCode");
    const [eventName, setEventName] = useState("");
    const [bookType, setBookType] = useState<CouponCodeBookType>(CouponCodeBookType.PRICE_DISCOUNT)
    const [generateAmount, setGenerateAmount] = useState<number>(0);
    const [discountType, setDiscountType] = useState<CouponDiscountType>(CouponDiscountType.PERCENT);
    const [discountAmount, setDiscountAmount] = useState("");
    const [selectedProductIdx, setSelectedProductIdx] = useState<number | null>(null);
    const [availableStartAt, setAvailableStartAt] = useState("")
    const [availableEndAt, setAvailableEndAt] = useState("")
    const isCodeDataSaved = useAppSelector(isCouponCodeDataSavedState);
    const {validateCouponCode} = useCouponCodeFunctions();

    const dispatch = useAppDispatch();
    const {timeConverter} = useTimeConverter();
    const navigator = useNavigate();

    // 쿠폰코드 생성
    const createCouponCode = () => {
        if (!validateCouponCode({
            eventName,
            generateAmount,
            discountType,
            discountAmount,
            availableStartAt,
            availableEndAt,
            bookType,
            selectedProductIdx
        })) {
            return;
        }

        const dto: CouponCodePostDTO = {
            eventName, generateAmount, discountType, discountAmount,
            availableStartAt: timeConverter.convertStartOfDayToUTC(availableStartAt),
            availableEndAt: timeConverter.convertStartOfDayToUTC(availableEndAt),
            applicableProductIdx: selectedProductIdx,
        }

        if (window.confirm("쿠폰코드를 등록하시겠습니까?")) {
            callAsync(
                dispatch(generateCouponCodesAsync(dto)).unwrap(),
                () => {
                    dispatch(setIsCouponCodeDataSaved(true));
                    setHasChangedValue(false);
                    alert("등록이 완료되었습니다.");
                    navigator(`/coupon/code-list`);
                },
                () => alert("일시적인 문제가 발생했습니다.\n다시 시도해주세요.")
            ).then();
        }
    }

    const [hasChangedValue, setHasChangedValue] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(!isCodeDataSaved && hasChangedValue);

    const changeValue = useCallback(
        (v: any, changeFunc: (_: any) => void) => {
            if (v) {
                dispatch(setIsCouponCodeDataSaved(false));
                setHasChangedValue(true);
            }
            changeFunc(v);
        },
        [dispatch, setHasChangedValue]
    );

    useEffect(() => {
        if (!isCodeDataSaved && showPrompt) {
            const isConfirmed = window.confirm(
                t("입력한 쿠폰코드 정보가 모두 사라집니다.\n정말 이 페이지를 벗어나시겠습니까?")
            );
            if (isConfirmed) {
                confirmNavigation();
            } else {
                cancelNavigation();
            }
        } else {
            confirmNavigation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCodeDataSaved, showPrompt, confirmNavigation, cancelNavigation]);

    const radios: Radio<CouponCodeBookType | CouponType>[] = [
        {
            check: (type) => type === CouponCodeBookType.PRICE_DISCOUNT,
            onClickFunc: () => setBookType(CouponCodeBookType.PRICE_DISCOUNT),
            text: t("creator.type.price"),
        },
        {
            check: (type) => type === CouponCodeBookType.PRODUCT_DISCOUNT,
            onClickFunc: () => setBookType(CouponCodeBookType.PRODUCT_DISCOUNT),
            text: t("creator.type.product"),
        },
    ];

    return (
        <Post title={t("creator.title")} buttonTitle={t("creator.title")} buttonOnClickAction={createCouponCode}>
            <>
                <div className={style.fieldLine}>
                    <Label104x46 text={t("creator.name.label")}/>
                    <CouponCommonNameField name={eventName} setName={(v) => changeValue(v, setEventName)}
                                           placeHolder={t("creator.name.placeholder")}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={t("creator.type.label")}/>
                    <CouponTypeCommonRadioField couponType={bookType} setCouponType={(v) => changeValue(v, setBookType)}
                                                radios={radios}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={t("creator.amount.label")}/>
                    <CouponNumberInputField amount={generateAmount} setAmount={(v) => changeValue(v, setGenerateAmount)}
                                            placeHolder={t("creator.amount.placeholder")}/>
                </div>

                <HrLine/>
                {bookType === CouponCodeBookType.PRODUCT_DISCOUNT && (
                    <CouponCreatorProductList setSelectedProductIdx={(v) => changeValue(v, setSelectedProductIdx)}/>
                )}

                <div className={style.fieldLine}>
                    <Label104x46 text={t("creator.benefits")}/>
                    <DiscountTypeRadioField
                        discountType={discountType}
                        setDiscountType={(v) => changeValue(v, setDiscountType)}
                    />
                    <div className={style.addedField}>
                        {discountType === CouponDiscountType.PERCENT ? (
                            <PercentField percent={discountAmount} setPercent={(v) => changeValue(v, setDiscountAmount)}/>
                        ) : (
                            <PointField point={discountAmount} setPoint={(v) => changeValue(v, setDiscountAmount)}/>
                        )}
                    </div>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={t("creator.grantedDate.label")}/>
                    <div className={style.addedField}>
                        <DurationField
                            availableStartAt={availableStartAt}
                            availableEndAt={availableEndAt}
                            setAvailableStartAt={(v) => changeValue(v, setAvailableStartAt)}
                            setAvailableEndAt={(v) => changeValue(v, setAvailableEndAt)}
                        />
                    </div>
                </div>
            </>
        </Post>
    );
}