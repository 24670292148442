import style from "../../../layout/content/post/creator.module.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ProductListObj } from "../../../types/product";
import { Table } from "../../../layout/content/list/table/Table";
import { useProductColumns } from "../../product/hooks/useProductColumns";
import { PageResponse } from "../../../types/page";
import { ActionButton, RedActionButton } from "../../../components/Buttons";
import { Label104x46 } from "../../../components/Label";
import { useTranslation } from "react-i18next";
import { useLoader } from "../../../hooks/common/useLoader";
import { useAppSelector, useLanguage } from "../../../hooks/hooks";
import { productStatus } from "../../../store/productSlice";
import { BodyScollTable } from "../../../layout/content/list/table/BodyScrollTable";
import { useConditionalProductList } from "../../product/hooks/useConditionalProductList";
import { useUtil } from "../../../util/hooks/useUtil";

interface Props {
  setSelectedProductIdx: (_: number) => void;
}

export function CouponCreatorProductList({ setSelectedProductIdx }: Props) {
  const { t } = useTranslation("coupon");
  const [productSearchWord, setProductSearchWord] = useState("");
  const [addedProduct, setAddedProduct] = useState<PageResponse<ProductListObj>>();
  const status = useAppSelector(productStatus);
  useLoader({ status });
  const { isKorean } = useLanguage();
  const { getDefaultPageResponseObject } = useUtil();

  const { getProductList, productList, pageCondition } = useConditionalProductList({
    searchWord: productSearchWord,
    isCouponUsable: true,
  });
  const columns = useProductColumns({
    stopSelling: null,
    resumeSelling: null,
    getProductList: getProductList(pageCondition),
    hasProductLink: false,
  });

  const onClickAddButton = useCallback(
    (p: ProductListObj) => {
      if (!addedProduct) {
        setAddedProduct(getDefaultPageResponseObject([p]));
      } else {
        if (addedProduct.content.length > 0) {
          alert(t("creator.product.alreadyAddedAlertMessage"));
          return;
        } else {
          setAddedProduct({
            ...addedProduct,
            content: [p],
          });
        }
      }
    },
    [addedProduct, t, setAddedProduct, getDefaultPageResponseObject]
  );

  const columnWidths = useMemo(
      () => (isKorean ? [76, 84, 240, 110, 74, 75, 87, 87, 101] : [87, 289, 152, 92, 119, 120, 120, 152, 87]),
      [isKorean]
  );

  const listColumns = useMemo(() => {
    const copiedColumns = [...columns];
    const filteredWidths = [...columnWidths];
    ["isCouponUsable", "idx"].forEach((accessor) => {
      const idx = copiedColumns.findIndex((c) => c.accessor === accessor);
      copiedColumns.splice(idx, 1);
      // filteredWidths.splice(idx, 1);
    });

    const productItemCountIdx = copiedColumns.findIndex((c) => c.accessor === "productItemCount");
    copiedColumns.splice(productItemCountIdx, 1, {
      accessor: "productItemCount",
      header: t("creator.product.unitItem"),
      sortable: false,
    });

    copiedColumns.push({
      accessor: "idx",
      header: "",
      sortable: false,
      width: columnWidths[columnWidths.length - 1],
      getNode: (a) => {
        if (addedProduct?.content.at(0)?.idx === a.idx) {
          return <span className={"blue"}>{t("creator.product.added")}</span>;
        } else {
          return (
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                onClickAddButton(a);
              }}
            >
              {t("creator.product.addButton")}
            </ActionButton>
          );
        }
      },
    });

    return copiedColumns.map((c, idx) => ({
      ...c,
      width: filteredWidths[idx] || columnWidths[columnWidths.length - 1]
    }));
  }, [columns, t, addedProduct?.content, onClickAddButton, columnWidths]);

  const addedProductColumns = useMemo(() => {
    const copiedColumns = [...columns];
    const couponUsableColumnIdx = copiedColumns.findIndex((c) => c.accessor === "isCouponUsable");
    copiedColumns.splice(couponUsableColumnIdx, 1);

    const foundIdx = copiedColumns.findIndex((c) => c.accessor === "idx");
    copiedColumns.splice(foundIdx, 1, {
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (_) => (
        <RedActionButton onClick={() => setAddedProduct(undefined)}>
          {t("creator.product.deleteButton")}
        </RedActionButton>
      ),
    });
    return copiedColumns.map((c, idx) => ({ ...c, width: columnWidths[idx] }));
  }, [columnWidths, columns, t]);

  useEffect(() => {
    getProductList({})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addedProduct?.content) {
      setSelectedProductIdx(addedProduct.content[0].idx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedProduct?.content]);

  return (
    <div className={`${style.fieldLine} ${style.tableLine}`}>
      <Label104x46 text={t("creator.product.search.label")} />
      <div className={style.tableArea}>
        <div className={style.textInput}>
          <input
            className={style.searchWordInput}
            type={"text"}
            placeholder={t("creator.product.search.placeholder")}
            value={productSearchWord}
            onChange={(e) => setProductSearchWord(e.target.value)}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              getProductList(pageCondition)();
            }}
          >
            {t("creator.product.search.button")}
          </button>
        </div>
        <div className={`${style.list} ${isKorean ? "" : style.notKO}`}>
          {productList ? (
            <BodyScollTable
              listData={productList}
              columns={listColumns}
              noDataText={t("creator.product.search.noData")}
              getList={getProductList}
              tableClassName={style.listTable}
              tbodyClassName={style.listTableBody}
              noResultRowClassName={style.noResultRow}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={`${style.selectedItem} ${isKorean ? "" : style.notKO}`}>
          <h3 className={style.title}>{t("creator.product.productToApplyCoupon")}</h3>
          <div className={style.list}>
            {!addedProduct || addedProduct.content.length === 0 ? (
              <span className={style.noItem}>{t("creator.product.noAddedProduct")}</span>
            ) : (
              <Table listData={addedProduct} columns={addedProductColumns} noDataText={""} getList={(_) => () => {}} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
