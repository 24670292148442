import style from "../style/couponCodeDetail.module.scss";
import {Label104x46} from "../../../components/Label";
import React from "react";

interface Args{
    label: string;
}
export function DetailLabelField({label}:Args) {

    return (
        <div className={style.field}>
            <Label104x46 className={style.label} text={label}/>
        </div>
    )
}