import style from "../../../layout/content/post/creator.module.scss";
import React from "react";

interface Props {
  name: string;
  setName: (_: string) => void;
  placeHolder: string
}

export function CouponCommonNameField({ name, setName, placeHolder }: Props) {

  return (
    <input
      className={style.inputField}
      type={"text"}
      value={name}
      required={true}
      onChange={(e) => setName(e.target.value)}
      placeholder={placeHolder}
    />
  );
}
