import {FilterNumber} from "../../layout/content/list/filter/FilterNumber";
import {FilterContentType} from "../../types/tableFilter";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import style from "./discount.module.scss";
import {useLanguage} from "../../hooks/hooks";

interface Props {
  percent: string;
  setPercent: (_: string) => void;
  label?: string;
}

export function PercentField({ percent, setPercent, label}: Props) {
  const { t } = useTranslation(["common"]);
  const { isKorean } = useLanguage();

  useEffect(() => {
    setPercent("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FilterNumber
        className={`${style.discountNumberField} ${isKorean ? "" : style.notKO}`}
        numberField={{
          type: FilterContentType.NUMBER,
          value: percent,
          placeholderVal: t("inputNumberPlaceholder"),
          onChangeFunc: (v) => {
              const value = Math.min(Number(v), 99);

              setPercent(value.toString())
          },
          isRequired: true,
          min: 0,
          max: 99,
        }}
      />
      {label ?? t("discount.percent")}
    </>
  );
}
