import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {executePromise} from "../util/sliceUtil";
import {
    CouponCodeDeleteDTO,
    CouponCodeListSearchCondition,
    CouponCodePostDTO,
    CouponCodePutDTO
} from "../types/couponcode";
import {CouponCodeAPI} from "../api/CouponCodeAPI";
import {RootState} from "./index";

// 쿠폰코드 리스트 조회
export const getCouponCodeListAsync =
    createAsyncThunk("couponCode/getCouponCodeList", (condition: CouponCodeListSearchCondition) =>
    executePromise(CouponCodeAPI.getCouponCodeList(condition))
);

// 쿠폰코드 생성
export const generateCouponCodesAsync =
    createAsyncThunk("couponCode/generateCouponCodes", (dto: CouponCodePostDTO) =>
    executePromise(CouponCodeAPI.generateCouponCodes(dto))
);

// 쿠폰코드 상세 조회
export const getCouponCodeAsync =
    createAsyncThunk("couponCode/getCouponCode", (idx: string) =>
    executePromise(CouponCodeAPI.getCouponCode(idx))
);

// 쿠폰코드 상세 수정
export const modifyCouponCodeAsync =
    createAsyncThunk("couponCode/modifyCouponCode", (dto: CouponCodePutDTO) =>
    executePromise(CouponCodeAPI.modifyCouponCode(dto))
);

// 쿠폰코드 상세 수정
export const updateCouponCodeStatusAsync =
    createAsyncThunk("couponCode/updateCouponCodeStatus", (idx: number) =>
    executePromise(CouponCodeAPI.updateCouponCodeStatus(idx))
);

// 쿠폰코드 삭제
export const removeCouponCodeStatusAsync =
    createAsyncThunk("couponCode/removeCouponCodeStatus", (dto: CouponCodeDeleteDTO) =>
    executePromise(CouponCodeAPI.removeCouponCodeStatus(dto))
);

// 쿠폰코드 엑셀 다운로드
export const getCouponCodeExcelDownloadAsync =
    createAsyncThunk("couponCode/getCouponCodeExcelDownload", (idx: number) =>
    executePromise(CouponCodeAPI.getCouponCodeExcelDownload(idx))
);

export const couponCodeSlice = createSlice({
    name: "couponCode",
    initialState: {
        isCodeDataSaved: false,
    },
    reducers: {
        setIsCouponCodeDataSaved: (state, action: PayloadAction<boolean>) => {
            state.isCodeDataSaved = action.payload;
        },
    },
});
export default couponCodeSlice.reducer;

export const { setIsCouponCodeDataSaved } = couponCodeSlice.actions;
export const isCouponCodeDataSavedState = (state: RootState) => state.couponCode.isCodeDataSaved;


