import React, { useMemo } from "react";
import { OrderListVO } from "../../../../../types/orders";
import { TableColumnData } from "../../../../../types/common";
import { useNationality, useNewWindow, useTimeConverter } from "../../../../../hooks/hooks";
import { LinkStyle } from "../../../../../components/styled/LinkStyle";
import { OrderItemColumn } from "../../../../order/component/OrderItemColumn";
import { numberWithCommas } from "../../../../../util/etcUtil";
import { OrderStatusStyle } from "../../../../order/component/OrderStatusStyle";
import { useTranslation } from "react-i18next";
import { useOrdersType } from "../../../../../types/hooks/useOrdersType";
import { usePaymentType } from "../../../../../types/hooks/usePaymentType";
import { useProductType } from "../../../../../types/hooks/useProductType";
import {PaymentWay} from "../../../../../types/payment";
import {OrderCodeStatusField} from "../../../../order/component/OrderCodeStatusField";

export default function useMemberOrderColumns() {
  const { t } = useTranslation("member");
  const { openOrderDetailPopup, openCustomerPopup } = useNewWindow();
  const { timeConverter } = useTimeConverter();
  const { toStringFromOrderStatus } = useOrdersType();
  const { toStringFromPaymentWay } = usePaymentType();
  const { toStringFromDisplayPosition } = useProductType();
  const { isKorean } = useNationality();

  const createAtColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "createAt",
      header: t("order.list.columns.orderedAt"),
      getNode: (o: OrderListVO) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            openOrderDetailPopup(o.idx);
          }}
        >
          <LinkStyle>{timeConverter.convertToLocalTime(o.createAt)}</LinkStyle>
          <br />
          {o.paidAt && <span>({timeConverter.convertToLocalTime(o.paidAt)})</span>}
        </div>
      ),
      sortable: true,
    }),
    [openOrderDetailPopup, t, timeConverter]
  );

  const displayPositionColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "displayPosition",
      header: t("order.list.columns.displayPosition"),
      sortable: false,
      getNode: (o: OrderListVO) => <span>{toStringFromDisplayPosition(o.displayPosition)}</span>,
    }),
    [t, toStringFromDisplayPosition]
  );

  const orderTitleColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "orderTitle",
      header: t("order.list.columns.productName"),
      sortable: false,
      getNode: (o: OrderListVO) => <OrderItemColumn orders={o} />,
    }),
    [t]
  );

  const regularPriceAmountColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "regularPriceAmount",
      header: t("order.list.columns.totalAmount"),
      sortable: false,
      getNode: (o: OrderListVO) =>
      <>
          {numberWithCommas(o.regularPriceAmount)}
          {o.paymentWay === PaymentWay.PAYPAL ? t("order.list.columns.usAmountUnit") : t("order.list.columns.amountUnit")}
      </>,
      isRightAlign: true,
    }),
    [t]
  );

  const payAmountColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "payAmount",
      header: t("order.list.columns.paidAmount"),
      sortable: false,
      getNode: (o: OrderListVO) =>
          <>
              {numberWithCommas(o.payAmount)}
              {o.paymentWay === PaymentWay.PAYPAL ? t("order.list.columns.usAmountUnit") : t("order.list.columns.amountUnit")}
          </>,
      isRightAlign: true,
    }),
    [t]
  );

  const paymentWayColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "paymentWay",
      header: t("order.list.columns.paymentWay"),
      sortable: false,
      getNode: (o: OrderListVO) => <span>{toStringFromPaymentWay(o.paymentWay)}</span>,
    }),
    [t, toStringFromPaymentWay]
  );

  const statusColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "status",
      header: t("order.list.columns.status"),
      sortable: false,
      getNode: (o: OrderListVO) => (
        <OrderStatusStyle orderStatus={o.status}>{toStringFromOrderStatus(o.status)}</OrderStatusStyle>
      ),
    }),
    [t, toStringFromOrderStatus]
  );

  const codeColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "affiliateCode",
      header: "코드",
      sortable: false,
      getNode: (o) => <OrderCodeStatusField orderList={o} />,
      hideThisColumn: !isKorean,
    }),
    [isKorean]
  );

  const buyerColumn: TableColumnData<OrderListVO> = useMemo(
    () => ({
      accessor: "buyerName",
      header: t("order.list.columns.buyerName"),
      sortable: false,
      getNode: (o: OrderListVO) => (
        <LinkStyle
          onClick={(e) => {
            e.preventDefault();
            openCustomerPopup(o.buyerIdx, o.customerType);
          }}
        >
          {o.buyerName}
        </LinkStyle>
      ),
    }),
    [openCustomerPopup, t]
  );

  return {
    userColumn: [
      createAtColumn,
      displayPositionColumn,
      orderTitleColumn,
      regularPriceAmountColumn,
      payAmountColumn,
      paymentWayColumn,
      statusColumn,
      codeColumn,
    ],
    organManagerColumn: [
      createAtColumn,
      displayPositionColumn,
      orderTitleColumn,
      regularPriceAmountColumn,
      payAmountColumn,
      paymentWayColumn,
      statusColumn,
    ],
    codeOrdersColumn: [
      { ...createAtColumn, sortable: false },
      buyerColumn,
      displayPositionColumn,
      orderTitleColumn,
      regularPriceAmountColumn,
      payAmountColumn,
      paymentWayColumn,
      codeColumn,
    ],
  };
}
