import {OrderListVO} from "../../../types/orders";
import {AnchorComponent} from "../../../layout/content/AnchorComponent";
import React from "react";
import {useNewWindow} from "../../../hooks/hooks";

interface Args {
    orderList: OrderListVO
}

export function OrderCodeStatusField({orderList}: Args) {
    const {openOrganManagerDetailAffiliateCodeTabPopup} = useNewWindow();
    return (
        <>
            {orderList.affiliateCode ? (
                <AnchorComponent
                    onClickFunc={(e) => {
                        e.preventDefault();
                        openOrganManagerDetailAffiliateCodeTabPopup(
                            orderList.organManagerIdx, orderList.affiliateCode
                        );
                    }}
                    content={orderList.affiliateCode}
                />
            ) : orderList.couponCode ? (
                <span>{orderList.couponCode}</span>
            ) : (
                <span>-</span>
            )}
        </>
    )
}