import {Table} from "../../../layout/content/list/table/Table";
import {useUtil} from "../../../util/hooks/useUtil";
import {useCouponCodeDetailColumns} from "../hooks/useCouponCodeDetailColumns";
import {CouponCodeVO} from "../../../types/couponcode";
import styled from "styled-components";

interface Args{
    couponCodeList: CouponCodeVO[]
}
const TableWrapperStyle = styled.span`
    max-height: 500px;
    overflow-y: auto;
`;
export function CouponCodeDetail({couponCodeList}:Args) {

    const { columns } = useCouponCodeDetailColumns();
    const { getDefaultPageResponseObject } = useUtil(); // 페이징이 아니지만 페이징으로 처리
    return(
        <TableWrapperStyle>
            <Table
                listData={getDefaultPageResponseObject(couponCodeList)}
                columns={columns}
                getList={() => () => {}}
                noDataText={"데이터가 조회되지 않습니다. 다시 시도해주세요."}
            />
        </TableWrapperStyle>
    )
}