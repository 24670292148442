import {useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {useMemo} from "react";
import {TableColumnData} from "../../../types/common";
import {ExternalVoucherListDTO, ExternalVoucherStatus} from "../../../types/externalVoucher";
import {useExternalVoucherConverter} from "./useExternalVoucherConverter";
import {ExternalVoucherButtons} from "./ExternalVoucherButtons";
import listStyle from "../../../layout/content/list/list.module.scss";

interface Args{
    refreshList: () => void;
}
export function useVoucherColumns({refreshList}:Args) {
    const {timeConverter} = useTimeConverter();
    const {openNewWindowWithURL} = useNewWindow();
    const { getVoucherStatusConverter, getVoucherChannelConverter} = useExternalVoucherConverter();

    const handleUserDetail = (userIdx: number) => {
        openNewWindowWithURL(`/detail/member/user/${userIdx}?type=SELF_INFO`, {
            width: 1000,
            height: 800,
        });
    }

    const statusStyle = (status: ExternalVoucherStatus) =>{
        switch (status) {
            case ExternalVoucherStatus.DELETED:
                return listStyle.deleted;
            case ExternalVoucherStatus.REGISTERED:
                return listStyle.blue;
            default:
                return ""
        }
    }

    const createAtColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "createAt",
            header: "등록일",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <span>{timeConverter.convertToLocalTime(vo.createAt)}</span>,
        }), [timeConverter]);

    const channelColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "externalChannel",
            header: "채널",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <>{getVoucherChannelConverter(vo.externalChannel)}</>,
            //eslint-disable-next-line
        }), []);

    const codeColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "code",
            header: "코드",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => (
                <span className={statusStyle(vo.externalVoucherStatus)}>
                    {vo.code}
                </span>
            )
            ,
            //eslint-disable-next-line
        }), []);

    const productNameColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "productNames",
            header: "부여상품명",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO):JSX.Element => (
                // *3 등 "* + 숫자" 형태 일때 볼드 처리 하도록 정규식 처리 + 배열값마다 쉼표 추가
                <>
                    {vo.productNames.map((name: string, idx: number): JSX.Element => (
                        <>
                            {idx > 0 && ', '}
                            {name.split(/(\*\d+)/).map((part: string, index: number) =>
                                part.match(/^\*\d+$/)
                                    ? <span key={index} className={listStyle.bold}>{part}</span>
                                    : part
                            )}
                        </>
                    ))}
                </>)
            //eslint-disable-next-line
        }), []);

    const purchasedUsernameColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "purchasedUserName",
            header: "이름",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <>
                {
                    (vo.userName === "" && vo.purchasedUserName === null)
                    ? "-" // 미등록 + 미안내 상태
                    : vo.userName
                            ? <span className={listStyle.underline} // 사용자 등록 완료
                                    onClick={() => handleUserDetail(vo.userIdx)}>
                                {vo.userName}
                              </span>
                            : vo.purchasedUserName || "" // 사용자 안내 완료
                }
            </>,
        }), []);

    const userIdColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "idx",
            header: "아이디",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => <>
                {vo.userId === ""
                    ? "-"
                    : <span className={listStyle.underline}
                            onClick={() => handleUserDetail(vo.userIdx)}>
                        {vo.userId}
                      </span> }
            </>,
        }), []);

    const statusColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "externalVoucherStatus",
            header: "상태",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => (
                <span className={statusStyle(vo.externalVoucherStatus)}>
                    {getVoucherStatusConverter(vo.externalVoucherStatus)}
                </span>),
            //eslint-disable-next-line
        }), []);


    const idxColumn: TableColumnData<ExternalVoucherListDTO> = useMemo(
        () => ({
            accessor: "idx",
            header: "관련 기능",
            sortable: false,
            getNode: (vo: ExternalVoucherListDTO) => (
                <ExternalVoucherButtons dto={vo} refreshList={refreshList}/>
            ),
            //eslint-disable-next-line
        }), []);


    const columns: TableColumnData<ExternalVoucherListDTO>[] = useMemo(
        () => [
            createAtColumn,
            channelColumn,
            codeColumn,
            productNameColumn,
            purchasedUsernameColumn,
            userIdColumn,
            statusColumn,
            idxColumn,
        ],
        [
            createAtColumn,
            channelColumn,
            codeColumn,
            productNameColumn,
            purchasedUsernameColumn,
            userIdColumn,
            statusColumn,
            idxColumn,
        ]
    );


    return {
        columns,
    };

}