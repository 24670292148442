import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  OrganVoucherHistoryCategory,
  VoucherHistoryListObj,
  VoucherStatus,
  VoucherStatusVO,
} from "../voucher";
import moment from "moment/moment";

export function useVoucherType() {
  const { t } = useTranslation("voucher");

  const toStringFromVoucherStatusVO = useCallback(
    (status: VoucherStatusVO) => {
      switch (status) {
        case VoucherStatusVO.EXPIRED:
          return t("voucherStatus.expired");
        case VoucherStatusVO.USABLE:
          return t("voucherStatus.usable");
        case VoucherStatusVO.USED:
          return t("voucherStatus.used");
        case VoucherStatusVO.COLLECTED:
          return t("voucherStatus.collected");
        case VoucherStatusVO.CANCELLED:
          return t("voucherStatus.collected");
        default:
          return "-";
      }
    },
    [t]
  );

  const toStringFromVoucherStatus = useCallback(
    (s: VoucherStatus) => {
      switch (s) {
        case VoucherStatus.USABLE:
          return t("voucherStatus.usable");
        case VoucherStatus.USED:
          return t("voucherStatus.used");
        case VoucherStatus.COLLECTED:
          return t("voucherStatus.collected");
        case VoucherStatus.CANCELLED:
          return t("voucherStatus.cancelled");
      }
    },
    [t]
  );

  const toVoucherVOStatus = useCallback((status: VoucherStatus, availableUntil: string) => {
    switch (status) {
      case VoucherStatus.USABLE:
        if (availableUntil && moment.utc(availableUntil).isBefore(moment())) {
          return VoucherStatusVO.EXPIRED;
        } else {
          return VoucherStatusVO.USABLE;
        }
      case VoucherStatus.USED:
        return VoucherStatusVO.USED;
      case VoucherStatus.COLLECTED:
        return VoucherStatusVO.COLLECTED;
      default:
        return VoucherStatusVO.UNDEFINED;
    }
  }, []);

  const toVoucherHistoryListVO = useCallback(
    (listDTO: VoucherHistoryListObj) => {
      return {
        ...listDTO,
        voucherVOStatus: toVoucherVOStatus(listDTO.unitVoucherStatus, listDTO.availableUntil),
      };
    },
    [toVoucherVOStatus]
  );

  const toStringFromVoucherType = useCallback(
    (type: string) => {
      switch (type) {
        case "KPASS":
          return t("voucherType.kpass");
        case "DCAS":
          return t("voucherType.dcas");
        case "CONSULTING":
          return t("voucherType.consulting");
        case "COUNSELING":
          return t("voucherType.counseling");
        default:
          return "";
      }
    },
    [t]
  );

  const toStringFromOrganVoucherHistoryCategory = useCallback(
    (category: OrganVoucherHistoryCategory) => {
      switch (category) {
        case OrganVoucherHistoryCategory.GRANTED:
          return t("organVoucherHistoryCategory.grant");
        case OrganVoucherHistoryCategory.COLLECTED:
          return t("organVoucherHistoryCategory.collect");
        case OrganVoucherHistoryCategory.CANCELLED:
          return t("organVoucherHistoryCategory.refund");
        case OrganVoucherHistoryCategory.GRANTED_FROM_ADMIN:
          return t("organVoucherHistoryCategory.granted");
        case OrganVoucherHistoryCategory.BOUGHT:
          return t("organVoucherHistoryCategory.buy");
      }
    },
    [t]
  );

  return {
    toStringFromVoucherStatusVO,
    toStringFromVoucherStatus,
    toVoucherVOStatus,
    toVoucherHistoryListVO,
    toStringFromVoucherType,
    toStringFromOrganVoucherHistoryCategory,
  };
}
