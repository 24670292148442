import styled from "styled-components";
import {OrdersVO} from "../../../../types/orders";
import {convertCurrencyUnit, numberWithCommas} from "../../../../util/etcUtil";
import {Panel} from "../../../../layout/content/detail/Panel";
import style from "../orderDetail.module.scss";
import {useOrderStatus} from "../../hooks/useOrderStatus";
import {useTranslation} from "react-i18next";
import {useLanguage} from "../../../../hooks/hooks";

const PaymentInfoListWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const PaymentInfoList = styled.div`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: ${(props) => props.theme.color.FGDarkBlue};
  border: 1px solid ${(props) => props.theme.color.cDE};
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;

  .price-wrap {
    width: 150px;
    border-right: 1px solid ${(props) => props.theme.color.cDE};
    &:last-child {
      border-right: unset;
    }

    &.flex1 {
      width: 100%;
    }
  }

  .header {
    background-color: ${(props) => props.theme.color.cF6};
    border-bottom: 1px solid ${(props) => props.theme.color.cDE};

    &.notKO {
      height: 56px;
    }
  }

  .price {
  }

  .disabled {
    font-weight: normal;
  }

  .header,
  .price {
    width: 100%;
    height: 46px;
    line-height: 1.3;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Symbol = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 20px;
`;

interface Props {
  orderVO: OrdersVO;
}

export function PaymentInfo({ orderVO }: Props) {
  const { isBeforePaid } = useOrderStatus();
  const { t } = useTranslation("order");
  const { isKorean } = useLanguage();
  const isThisUserUseCode = orderVO.salesCodeDiscountAmount > 0 || orderVO.couponCodeDiscountAmount > 0;
  const couponDiscountAmount = isThisUserUseCode
      ? orderVO.salesCodeDiscountAmount + orderVO.couponCodeDiscountAmount
      : 0;

  return (
    <Panel id="PAYMENT" title={t("paymentInfo.title")} panelClassName={style.scrollMarginTop}>
      <PaymentInfoListWrap>
        <PaymentInfoList>
          <div className="price-wrap">
            <div className={`header ${isKorean ? "" : "notKO"}`}>{t("paymentInfo.totalProductAmount")}</div>
            <div className="price">
              {numberWithCommas(orderVO.regularPriceAmount)}
              {convertCurrencyUnit(orderVO.paymentWay, t)}
            </div>
          </div>
        </PaymentInfoList>

        <Symbol>-</Symbol>

        <PaymentInfoList style={{ flex: 1 }}>
          <div className="price-wrap flex1">
            <div className={`header ${isKorean ? "" : "notKO"}`}>{t("paymentInfo.totalDiscountAmount")}</div>
            <div className="price">
              {numberWithCommas(
                orderVO.productDiscountAmount + orderVO.couponDiscountAmount + couponDiscountAmount
              )}
              {numberWithCommas(orderVO.productDiscountAmount
                  + orderVO.couponDiscountAmount
                  + orderVO.salesCodeDiscountAmount)=== '0'
                ? ''
                :convertCurrencyUnit(orderVO.paymentWay, t)}
            </div>
          </div>

          <div className="price-wrap disabled flex1">
            <div className={`header ${isKorean ? "" : "notKO"}`}>{t("paymentInfo.productDiscount")}</div>
            <div className="price">
              {numberWithCommas(orderVO.productDiscountAmount)}
              {numberWithCommas(orderVO.productDiscountAmount) === '0' ? '' : convertCurrencyUnit(orderVO.paymentWay, t)}
            </div>
          </div>

          {orderVO.couponDiscountAmount > 0 && (
            <div className="price-wrap disabled flex1">
              <div className={`header ${isKorean ? "" : "notKO"}`}>{t("paymentInfo.couponDiscount")}</div>
              <div className="price">
                {numberWithCommas(orderVO.couponDiscountAmount)}
              </div>
            </div>
          )}

          {/*{orderVO.salesCodeDiscountAmount > 0 && (*/}
          {couponDiscountAmount > 0 && (
            <div className="price-wrap disabled flex1">
              <div className={`header ${isKorean ? "" : "notKO"}`}>{t("paymentInfo.codeDiscount")}</div>
              <div className="price">
                {numberWithCommas(couponDiscountAmount)}
                {numberWithCommas(couponDiscountAmount) === '0'
                  ? ''
                  :convertCurrencyUnit(orderVO.paymentWay, t)}
              </div>
            </div>
          )}
        </PaymentInfoList>

        <Symbol>=</Symbol>

        <PaymentInfoList>
          <div className="price-wrap">
            <div className={`header ${isKorean ? "" : "notKO"}`}>{t("paymentInfo.expectedPaidAmount")}</div>
            <div className="price">
              {isBeforePaid(orderVO.status) ? numberWithCommas(orderVO.payAmount) : 0}
              {isBeforePaid(orderVO.status) ? convertCurrencyUnit(orderVO.paymentWay, t) : ''}
            </div>
          </div>
        </PaymentInfoList>

        <PaymentInfoList>
          <div className="price-wrap">
            <div className={`header ${isKorean ? "" : "notKO"}`}>{t("paymentInfo.actualPaidAmount")}</div>
            <div className="price">
              {!isBeforePaid(orderVO.status) ? numberWithCommas(orderVO.payAmount) : 0}
              {convertCurrencyUnit(orderVO.paymentWay, t)}
            </div>
          </div>
        </PaymentInfoList>
      </PaymentInfoListWrap>
    </Panel>
  );
}
