import style from "../style/couponCodeDetail.module.scss"
import React, {useState} from "react";
import {DetailLabelAndValueField} from "./DetailLabelAndValueField";
import {useCouponCodeConverter} from "../hooks/useCouponCodeConverter";
import {HrLine} from "../../../layout/content/post/HrLine";
import {CouponDiscountType} from "../../coupon/CouponCreator";
import {numberWithCommas, widowOpenerReload} from "../../../util/etcUtil";
import {useAppDispatch, useTimeConverter} from "../../../hooks/hooks";
import {DetailLabelField} from "./DetailLabelField";
import {DetailProductInfoField} from "./DetailProductInfoField";
import {CouponCodeDetailDTO, CouponCodePutDTO, DetailType} from "../../../types/couponcode";
import {EventNameField} from "./EventNameField";
import {UsagePeriodField} from "./UsagePeriodField";
import {useCouponCodeFunctions} from "../hooks/useCouponCodeFunctions";
import {callAsync} from "../../../util/sliceUtil";
import {modifyCouponCodeAsync} from "../../../store/couponCodeSlice";

interface Args {
    couponCodeDetail: CouponCodeDetailDTO
    type: DetailType;
}

export function CouponCodeBookDetail({couponCodeDetail, type}: Args) {
    const {getBookTypeConverter} = useCouponCodeConverter();
    const {timeConverter} = useTimeConverter();
    const {alertAndReturn} = useCouponCodeFunctions();

    const [eventName, setEventName] = useState(couponCodeDetail.eventName)
    const [availableStartAt, setAvailableStartAt] =
        useState(timeConverter.convertToFilterDateString(couponCodeDetail.availableStartAt));
    const [availableEndAt, setAvailableEndAt] =
        useState(timeConverter.convertToFilterDateString(couponCodeDetail.availableEndAt));

    const dispatch = useAppDispatch();

    const benefitUnit = couponCodeDetail?.discountType === CouponDiscountType.PERCENT ? "%" : "원";
    const isThisCouponCodeForProduct = couponCodeDetail.productListDTO.idx !== null;

    const handleSubmitCouponCode = () => {
        if (eventName === "") alertAndReturn("쿠폰 코드 명을 입력해주세요.");
        if (availableStartAt === "") alertAndReturn("쿠폰 코드 사용 시작일을 선택해주세요.");
        if (availableEndAt === "") alertAndReturn("쿠폰 코드 사용 종료일을 선택해주세요.");

        const dto: CouponCodePutDTO = {
            idx: couponCodeDetail.bookIdx,
            eventName: eventName,
            availableStartAt: timeConverter.convertStartOfDayToUTC(availableStartAt),
            availableEndAt: timeConverter.convertStartOfDayToUTC(availableEndAt),
        }

        if (window.confirm("쿠폰코드를 수정하시겠습니까?")) {
            callAsync(
                dispatch(modifyCouponCodeAsync(dto)).unwrap(),
                () => {
                    alert("수정이 완료되었습니다.");
                    widowOpenerReload();
                },
                () => alert("일시적인 문제가 발생했습니다.\n다시 시도해주세요.")
            ).then();
        }
    }

    return (
        <div className={style.fieldLine}>
            <EventNameField
                type={type}
                value={eventName}
                setValue={setEventName}
            />

            <DetailLabelAndValueField
                label="쿠폰 코드 유형"
                value={getBookTypeConverter(couponCodeDetail.bookBenefitType)}
            />

            <DetailLabelAndValueField
                label="생성 개수"
                value={`${couponCodeDetail.generateAmount} 개`}
            />

            <HrLine/>

            {isThisCouponCodeForProduct && (
                <>
                    <DetailLabelField label="적용 상품"/>
                    <DetailProductInfoField productResult={couponCodeDetail.productListDTO}/>
                </>
            )}

            <DetailLabelAndValueField
                label="혜택"
                value={`${numberWithCommas(couponCodeDetail.discountAmount)} ${benefitUnit} 할인`}
            />

            <UsagePeriodField
                type={type}
                availableStartAt={availableStartAt}
                setAvailableStartAt={setAvailableStartAt}
                availableEndAt={availableEndAt}
                setAvailableEndAt={setAvailableEndAt}
            />

            {type === DetailType.MODIFY && (
                <div className={style.modifySubmitButton}
                     onClick={handleSubmitCouponCode}>쿠폰 코드 수정
                </div>
            )}
        </div>
    );
}