import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./lang";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ContentMain } from "./layout/content/ContentMain";
import { AuthProvider } from "./features/account/auth/AuthProvider";
import { AccountHome } from "./layout/account/AccountHome";
import { RequireAuth } from "./features/account/auth/RequireAuth";
import { Login } from "./features/account/Login";
import { SignUp } from "./features/account/SignUp";
import { SignUpSuccess } from "./features/account/SignUpSuccess";
import { Member } from "./features/member/Member";
import { MemberUser } from "./features/member/user/MemberUser";
import { MemberAdmin } from "./features/member/admin/MemberAdmin";
import { MemberConsultant } from "./features/member/consultant/MemberConsultant";
import { FindAccount } from "./features/account/find_account/FindAccount";
import { FindIDSuccess } from "./features/account/find_account/FindIDSuccess";
import { FindPWSuccess } from "./features/account/find_account/FindPWSuccess";
import { ContentDetail } from "./layout/content/detail/ContentDetail";
import { MemberUserDetail } from "./features/member/user/MemberUserDetail";
import { Order } from "./features/order/Order";
import { BlockMember } from "./features/member/components/popup/blockmember/BlockMember";
import { GrantCouponModal } from "./features/coupon/GrantCouponModal";
import { Test } from "./features/test/Test";
import { Coupon } from "./features/coupon/Coupon";
import { CouponCreator } from "./features/coupon/CouponCreator";
import { MemberAdminDetail } from "./features/member/admin/detail/MemberAdminDetail";
import { GrantProductModal } from "./features/product/modal/GrantProductModal";
import { ChangingPassword } from "./features/member/components/popup/changepassword/ChangingPassword";
import { AdminRole } from "./features/member/components/popup/role/AdminRole";
import { ProductCreator } from "./features/product/edit/ProductCreator";
import { UnitItemCreator } from "./features/product/unit_item/UnitItemCreator";
import { ProductDetailModal } from "./features/product/edit/ProductDetailModal";
import { CollectProductModal } from "./features/product/modal/CollectProductModal";
import { LockAccount } from "./features/account/lock/LockAccount";
import { NoAuthorization } from "./features/account/no_auth/NoAuthorization";
import { LinkedProducts } from "./features/product/unit_item/LinkedProducts";
import { ComponentGuide } from "./doc/ComponentGuide";
import { SampleContentDetail } from "./doc/components/sample/SampleContentDetail";
import { OrderDetail } from "./features/order/detail/OrderDetail";
import { MemberOrganManagerDetail } from "./features/member/organmanager/detail/MemberOrganManagerDetail";
import { RefundAccountModal } from "./features/order/detail/popup/RefundAccountModal";
import { RefundModal } from "./features/order/detail/popup/RefundModal";
import { OrganManagerGrantVoucher } from "./features/member/organmanager/components/popup/OrganManagerGrantVoucher";
import { MemberCreatedTesterDetail } from "./features/member/tester/MemberCreatedTesterDetail";
import { PendingAdminApproval } from "./features/account/no_auth/PendingAdminApproval";
import { SignUpCompleted } from "./features/account/SignUpCompleted";
import { NewWindowProvider } from "./hooks/newwindow/NewWindowProvider";
import { Home } from "./layout/content/Home";
import { MemberUserWrapper } from "./features/member/user/MemberUserWrapper";
import { MemberTesterWrapper } from "./features/member/tester/MemberTesterWrapper";
import { MemberOrganManagerWrapper } from "./features/member/organmanager/MemberOrganManagerWrapper";
import { MemberAdminWrapper } from "./features/member/MemberAdminWrapper";
import { KpassTestListWrapper } from "./features/test/KpassTestListWrapper";
import { OrderListWrapper } from "./features/order/OrderListWrapper";
import { ProductListWrapper } from "./features/product/ProductListWrapper";
import { UnitItemListWrapper } from "./features/product/UnitItemListWrapper";
import { CouponListWrapper } from "./features/coupon/CouponListWrapper";
import { CodeCreator } from "./features/affiliate_code/CodeCreator";
import { CodeListWrapper } from "./features/affiliate_code/CodeListWrapper";
import { SettlementListWrapper } from "./features/affiliate_code/SettlementListWrapper";
import { PartnerCompanyListWrapper } from "./features/affiliate_code/PartnerCompanyListWrapper";
import { PartnerCompanyCreator } from "./features/affiliate_code/PartnerCompanyCreator";
import { PartnerCompanyDetail } from "./features/affiliate_code/partner_company/PartnerCompanyDetail";
import { PopupListWrapper } from "./features/catalog/popup/PopupListWrapper";
import { PopupCreator } from "./features/catalog/popup/PopupCreator";
import { BannerListWrapper } from "./features/catalog/banner/BannerListWrapper";
import { BannerCreator } from "./features/catalog/banner/BannerCreator";
import { AffiliateCodeDuration } from "./features/affiliate_code/AffiliateCodeDuration";
import { PopupDetail } from "./features/catalog/popup/PopupDetail";
import BannerDetail from "./features/catalog/banner/BannerDetail";
import {DcasTestListWrapper} from "./features/test/DcasTestListWrapper";
import {OrganManagerRegistration} from "./features/product/modal/OrganManagerRegistration";
import {MemberSimpleTesterWrapper} from "./features/member/simpletester/MemberSimpleTesterWrapper";
import {VoucherListWrapper} from "./features/external_voucher/VoucherListWrapper";
import {VoucherInformation} from "./features/external_voucher/VoucherInformation";
import {VoucherGeneration} from "./features/external_voucher/VoucherGeneration";
import {VoucherAssignment} from "./features/external_voucher/VoucherAssignment";
import {CouponCodeListWrapper} from "./features/couponcode/list/CouponCodeListWrapper";
import {CouponCodeCreator} from "./features/couponcode/list/CouponCodeCreator";
import {CouponCodeDetailWrapper} from "./features/couponcode/detail/CouponCodeDetailWrapper";
import {CouponCodeModifier} from "./features/couponcode/CouponCodeModifier";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <NewWindowProvider>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<App />}>
                <Route path={"guide"} element={<ComponentGuide />} />
                <Route path={"login"} element={<AccountHome />}>
                  <Route path={""} element={<Login />} />
                </Route>
                <Route path={"sign-up"} element={<AccountHome />}>
                  <Route path={""} element={<SignUp />} />
                  <Route path={"success"} element={<SignUpSuccess />} />
                  <Route path={"completed"} element={<SignUpCompleted />} />
                </Route>
                <Route path={"find-account"} element={<AccountHome />}>
                  <Route path={""} element={<FindAccount />} />
                  <Route path={"id-success"} element={<FindIDSuccess />} />
                  <Route path={"pw-success"} element={<FindPWSuccess />} />
                </Route>
                <Route path={"lock-account"} element={<AccountHome />}>
                  <Route path={""} element={<LockAccount />} />
                </Route>
                <Route path={"no-authorization"} element={<AccountHome />}>
                  <Route path={""} element={<NoAuthorization />} />
                </Route>
                <Route path={"pending-admin-approval"} element={<AccountHome />}>
                  <Route path={""} element={<PendingAdminApproval />} />
                </Route>
                <Route
                  path=""
                  element={
                    <RequireAuth>
                      <ContentMain />
                    </RequireAuth>
                  }
                >
                  <Route path={""} element={<Home />} />
                  <Route path="member" element={<Member />}>
                    <Route path={"user"} element={<MemberUserWrapper />} />
                    <Route path={"profiles"} element={<MemberTesterWrapper />} />
                    <Route path={"simple-tester"} element={<MemberSimpleTesterWrapper />} />
                    <Route path={"organ-manager"} element={<MemberOrganManagerWrapper />} />
                    <Route path={"organ-manager/post"} element={<OrganManagerRegistration />} />
                    <Route path={"admin"} element={<MemberAdminWrapper />} />
                    <Route path={"consultant"} element={<MemberConsultant />} />
                    <Route path={"expert"} element={<MemberConsultant />} />
                  </Route>
                  <Route path="test/" element={<Test />}>
                    <Route path={"kpass"} element={<KpassTestListWrapper />} />
                    <Route path={"dcas"} element={<DcasTestListWrapper />} />
                  </Route>
                  <Route path="order" element={<Order />}>
                    <Route path={"list"} element={<OrderListWrapper />} />
                    <Route path={"external-voucher"} element={<VoucherListWrapper />} />
                  </Route>
                  <Route path="product" element={<Member />}>
                    <Route path={"list"} element={<ProductListWrapper />} />
                    <Route path={"unit-item-list"} element={<UnitItemListWrapper />} />
                    <Route path={"post"} element={<ProductCreator />} />
                  </Route>
                  <Route path="consulting" element={<Member />}>
                    <Route path={"list"} element={<MemberUser />} />
                    <Route path={"unit-item-list"} element={<MemberAdmin />} />
                    <Route path={"post"} element={<MemberConsultant />} />
                  </Route>
                  <Route path={"coupon"} element={<Coupon />}>
                    <Route path={"code-list"} element={<CouponCodeListWrapper />} />
                    <Route path={"code-post"} element={<CouponCodeCreator />} />
                    <Route path={"list"} element={<CouponListWrapper />} />
                    <Route path={"post"} element={<CouponCreator />} />
                  </Route>
                  <Route path={"affiliate-code"}>
                    <Route path={"code-list"} element={<CodeListWrapper />} />
                    <Route path={"post"} element={<CodeCreator />} />
                    <Route path={"settlement-list"} element={<SettlementListWrapper />} />
                    <Route path={"partner-list"} element={<PartnerCompanyListWrapper />} />
                    <Route path={"partner-post"} element={<PartnerCompanyCreator />} />
                  </Route>
                  <Route path={"catalog"}>
                    <Route path={"popup-list"} element={<PopupListWrapper />} />
                    <Route path={"popup-post"} element={<PopupCreator />} />
                    <Route path={"banner-list"} element={<BannerListWrapper />} />
                    <Route path={"banner-post"} element={<BannerCreator />} />
                  </Route>
                </Route>
                <Route
                  path={"detail"}
                  element={
                    <RequireAuth>
                      <ContentDetail />
                    </RequireAuth>
                  }
                >
                  <Route path={"member/user/:id"} element={<MemberUserDetail />} />
                  <Route path={"member/admin/:id"} element={<MemberAdminDetail />} />
                  <Route path={"member/organ-manager/:id"} element={<MemberOrganManagerDetail />} />
                  <Route path={"member/tester/:id"} element={<MemberCreatedTesterDetail />} />
                  <Route path={"member/block"} element={<BlockMember />} />
                  <Route path={"member/admin/password"} element={<ChangingPassword />} />
                  <Route path={"member/admin/role"} element={<AdminRole />} />
                  <Route path={"coupon/grant"} element={<GrantCouponModal />} />
                  <Route path={"product/grant"} element={<GrantProductModal />} />
                  <Route path={"product/collect"} element={<CollectProductModal />} />
                  <Route path={"product/:id"} element={<ProductDetailModal />} />
                  <Route path={"order/:id"} element={<OrderDetail />} />
                  <Route path={"unit-item/new"} element={<UnitItemCreator />} />
                  <Route path={"unit-item/:id/products"} element={<LinkedProducts />} />
                  <Route path={"sample/:id"} element={<SampleContentDetail />} />
                  <Route path={"order/refund-account"} element={<RefundAccountModal />} />
                  <Route path={"order/:id/refund"} element={<RefundModal />} />
                  <Route path={"organ-manager/grant-voucher"} element={<OrganManagerGrantVoucher />} />
                  <Route path={"partner/:id"} element={<PartnerCompanyDetail />} />
                  <Route path={"affiliate-code/:id"} element={<AffiliateCodeDuration />} />
                  <Route path={"popup/:id"} element={<PopupDetail />} />
                  <Route path={"banner/:id"} element={<BannerDetail />} />
                  <Route path={"external-voucher/:id"} element={<VoucherInformation />} />
                  <Route path={"external-voucher/generate"} element={<VoucherGeneration />} />
                  <Route path={"external-voucher/assign/:id"} element={<VoucherAssignment />} />
                  <Route path={"coupon-code/:id"} element={<CouponCodeDetailWrapper />} />
                  <Route path={"coupon-code/modify/:id"} element={<CouponCodeModifier />} />
                </Route>
              </Route>
            </Routes>
          </AuthProvider>
        </NewWindowProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
