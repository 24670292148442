import style from "../../layout/content/post/creator.module.scss";
import React from "react";

interface Args {
    amount: number;
    setAmount: (_: number) => void;
    placeHolder: string;
}

export function CouponNumberInputField({amount, setAmount, placeHolder}: Args) {
    const MAX_CREATE_NUMBER = 9999

    return (
        <div className={style.numberInputWrapper}>
            <input
                className={style.numberInput}
                type={"number"}
                value={amount === 0 ? "" : amount}
                required={true}
                onChange={(e) => {
                    const value = Number(e.target.value);
                    if (!isNaN(value) && value >= 0) {
                        const limitedValue = Math.min(value, MAX_CREATE_NUMBER);
                        setAmount(limitedValue);
                    }
                }}
                placeholder={placeHolder}
                max={MAX_CREATE_NUMBER}
            />개
        </div>

    );
}