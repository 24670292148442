import style from "../../style/grantModal.module.scss";
import {Label104x46} from "../../../components/Label";
import React from "react";
import {useLanguage} from "../../../hooks/hooks";

interface Args{
    title: string;
    value: string;
}
export function LabelAndTextField({title, value}:Args) {
    const {isKorean} = useLanguage();

    const renderValue = (text: string): JSX.Element => (
        <>
            {text.split(/(\*\d+)/).map((part: string, index: number) =>
                part.match(/^\*\d+$/)
                    ? <span key={index} className={style.bold}>{part}</span>
                    : part
            )}
        </>
    );

    return (
        <div className={style.textWrapper}>
            <Label104x46
                text={title}
                className={`${style.informationModalLabel} ${isKorean ? "" : style.notKO}`}/>
            <div className={style.text}>
                {renderValue(value)}
            </div>
        </div>
    )
}