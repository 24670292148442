import style from "../../../layout/content/post/creator.module.scss";
import {CouponType} from "../../../types/coupon";
import React from "react";
import {Radio, RadioGroup} from "../../../components/RadioGroup";
import {CouponCodeBookType} from "../../../types/couponcode";

interface Props {
  couponType: CouponType | CouponCodeBookType;
  setCouponType: (_: CouponType | CouponCodeBookType) => void;
  radios: Radio<CouponType | CouponCodeBookType>[];
}

export function CouponTypeCommonRadioField({ couponType, setCouponType, radios }: Props) {

  return (
    <div className={style.radioGroup} style={{display:"flex"}}>
      <RadioGroup groupName={"couponType"} isRequired={true} radios={radios} selectedValue={couponType} />
    </div>
  );
}
