import {CouponCodeBookDTO, ManagementStatus} from "../../../types/couponcode";
import listStyle from "../../../layout/content/list/list.module.scss";
import {ActionButton, ActiveActionButton} from "../../../components/Buttons";
import {useCouponCodeStatusUpdater} from "../hooks/useCouponCodeStatusUpdater";

interface Args {
    data: CouponCodeBookDTO
    onClickFunction: (bookIdx: number) => void;
    refreshList: () => void;
}

export function CouponCodeListButton({data, onClickFunction, refreshList}: Args) {
    const {updateCouponCodeStatus} = useCouponCodeStatusUpdater();
    const isDisabled = data.managementStatus === ManagementStatus.DISABLED;
    const statusText = isDisabled ? "사용재개" : "사용중지";

    const handleStatusUpdate = () => {
        if (window.confirm(`선택한 쿠폰코드를 ${statusText} 상태로 변경하시겠습니까?`)) {
            updateCouponCodeStatus(data.idx).then(() => refreshList());
        }
    };

    const StatusButton = isDisabled ? ActiveActionButton : ActionButton;

    return (
        <div className={listStyle.additionalButtons}>
            <ActionButton onClick={() => onClickFunction(data.idx)}>
                상세보기
            </ActionButton>
            <StatusButton onClick={handleStatusUpdate}>
                {statusText}
            </StatusButton>
        </div>
    );
}