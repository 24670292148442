import {useAppDispatch, useListFilterValues, useNationality, useTimeConverter} from "../../../../hooks/hooks";
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_SORT,
  ordersToSortArr,
  PageResponse,
  PageSearchCondition,
} from "../../../../types/page";
import {ProfileQueryType,} from "../../../../types/userProfile";
import {useEffect, useMemo, useState} from "react";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useSortParam} from "../../../../hooks/list/useSortParam";
import {dateToString} from "../../../../util/dateUtil";
import {SimpleTesterListQueryType} from "./useSimpleTesterFilterValues";
import {SimpleSearchCondition, SimpleTesterListDTO, SimpleTesterVO} from "../../../../types/tester";
import {getSimpleTester} from "../../../../store/simpleTestSlice";

interface Args {
  onCompletedCallback?: () => void;
}
export function useSimpleTesterList({ onCompletedCallback }: Args) {
  // const { t } = useTranslation("tester");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { toSortArray } = useSortParam();
  const { timeConverter } = useTimeConverter();

  const [testers, setTesters] = useState<PageResponse<SimpleTesterVO>>();
  const [totalTesterCount, setTotalTesterCount] = useState<number>(0);


  const pageCondition = useMemo(
    () => ({
      page: testers?.number ?? 0,
      size: testers?.size ?? 20,
      sort: ordersToSortArr(testers?.orders),
    }),
    [testers]
  );
  const { nationality } = useNationality();

  const getTesterList = async (param: SimpleSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<SimpleTesterListDTO> = await dispatch(getSimpleTester(param)).unwrap();
      if (isFirstLoad) {
        setTotalTesterCount(result.totalElements);
      } else {
        setTesters({
          ...result,
          content: result.content.map((dto) => SimpleTesterListDTO.toSimpleTesterListVO(dto)),
        });
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  const searchTesterList = (pageSearchCondition: PageSearchCondition) => {
    const param: SimpleTesterListQueryType = {
      searchWordType: filterValues.searchWordTypeFilterValue.value,
      searchWord: filterValues.searchWordFilterValue.value
        ? filterValues.searchWordFilterValue.value.trim()
        : undefined,
      createdAtFrom: filterValues.createdAtFromFilterValue.value
        ? dateToString(filterValues.createdAtFromFilterValue.value)
        : undefined,
      createdAtTo: filterValues.createdAtToFilterValue.value
        ? dateToString(filterValues.createdAtToFilterValue.value)
        : undefined,
      accessLocation: filterValues.accessLocationFilterValue?.value,
      isAgreementMarketing: filterValues.isAgreementMarketingFilterValue?.value
    };

    return () => {
      searchList(pageSearchCondition, param)();
    };
  };

  useEffect(() => {
    getTesterList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      getTesterList({
        pageCondition: {
          page: listQueryType.page ? Number(listQueryType.page) : DEFAULT_PAGE,
          size: listQueryType.size ? Number(listQueryType.size) : DEFAULT_SIZE,
          sort: listQueryType.sort ? toSortArray(listQueryType.sort) : DEFAULT_SORT,
        },

        q: filterValues.searchWordFilterValue.value?.trim(),
        qtype: listQueryType.searchWordType ? (listQueryType.searchWordType as ProfileQueryType) : undefined,
        createdAtFrom: listQueryType.createdAtFrom
          ? timeConverter.convertToFilterStartOfDay(listQueryType.createdAtFrom)
          : undefined,
        createdAtTo: listQueryType.createdAtTo
          ? timeConverter.convertToFilterEndOfDay(listQueryType.createdAtTo)
          : undefined,
        accessLocation: filterValues.accessLocationFilterValue.value,
        isAgreementMarketing: listQueryType.isAgreementMarketing
            ? filterValues.isAgreementMarketingFilterValue.value === 'Y'
            : undefined,
      }).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    searchTesterList,
    totalTesterCount,
    testers,
    pageCondition,
  };
}
