import {ListFilterValuesContext} from "../../../hooks/list/ListFilterValuesContext";
import {useCouponCodeFilterValues} from "./useCouponCodeFilterValues";
import {CouponCodeList} from "./CouponCodeList";

export function CouponCodeListWrapper() {
    const {pageInfoOnFilter, filterValues, searchList, listQueryType}
        = useCouponCodeFilterValues();

    return (
        <ListFilterValuesContext.Provider
            value={{
                filterValues,
                pageInfoOnFilter,
                searchList,
                listQueryType,
            }}
        >
            <CouponCodeList />
        </ListFilterValuesContext.Provider>
    )
}