import {Panel} from "../../layout/content/detail/Panel";
import style from "./style/couponCodeDetail.module.scss";
import {CouponCodeBookDetail} from "./detail/CouponCodeBookDetail";
import {PanelWrapper} from "../../layout/content/detail/PanelWrapper";
import React, {useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {useCouponCodeGetter} from "./hooks/useCouponCodeGetter";
import {DetailType} from "../../types/couponcode";

export function CouponCodeModifier() {

    const {id} = useParams<"id">();
    const formRef = useRef<HTMLFormElement>(null);
    const {getCouponCode, couponCodeDetail} = useCouponCodeGetter();
    useEffect(() => {
        id &&
        getCouponCode(id).then();
        //eslint-disable-next-line
    }, [id]);

    return (
        <PanelWrapper>
            {couponCodeDetail && id && (
                <>
                    <Panel title={"쿠폰 코드 상세정보"}>
                        <form className={style.form} ref={formRef} onSubmit={(e) => e.preventDefault()}>
                            <CouponCodeBookDetail couponCodeDetail={couponCodeDetail}
                                                  type={DetailType.MODIFY}/>
                        </form>
                    </Panel>
                </>
            )}
        </PanelWrapper>
    );
}