import listStyle from "../../../layout/content/list/list.module.scss";
import {TableColumnData} from "../../../types/common";
import {CouponCodeVO} from "../../../types/couponcode";
import {useNewWindow} from "../../../hooks/hooks";

export function useCouponCodeDetailColumns() {
    const {openNewWindowWithURL} = useNewWindow();

    const handleUserDetailPage = (userIdx: number | null) => {
        userIdx &&
        openNewWindowWithURL(`/detail/member/user/${userIdx}?type=SELF_INFO`, {
            width: 1000,
            height: 800,
        });
    }

    const columns: TableColumnData<CouponCodeVO>[] = [
        {
            accessor: "code",
            header: "쿠폰 코드",
            sortable: false,
            getNode: (it) => <>{it.code}</>,
        },
        {
            accessor: "usedByUserName",
            header: "이름",
            sortable: false,
            getNode: (it) =>
                <span className={it.usedByUserName ? listStyle.underline : ""}
                      onClick={() => handleUserDetailPage(it.usedByUserIdx)}>
                {it.usedByUserName ?? "-"}
            </span>,
        },
        {
            accessor: "usedByUserId",
            header: "아이디",
            sortable: false,
            getNode: (it) =>
                <span className={it.usedByUserId ? listStyle.underline : ""}
                      onClick={() => handleUserDetailPage(it.usedByUserIdx)}>
                {it.usedByUserId ?? "-"}
            </span>,
        },
    ]
    return {
        columns
    }
}