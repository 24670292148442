import {useListFilterValues, useTimeConverter} from "../../../hooks/hooks";
import {useMemo} from "react";
import {FilterContentType, TableFilter} from "../../../types/tableFilter";
import {ExternalVoucherFilterWordType} from "../../../types/externalVoucher";
import {useTranslation} from "react-i18next";
import {CouponCodeBookStatus, CouponCodeBookType, CouponCodeFilterWordType} from "../../../types/couponcode";
import {useCouponCodeConverter} from "./useCouponCodeConverter";

interface Args {
    search: () => void;
}

export function useCouponCodeFilters({search}:Args) {
    const {t} = useTranslation(["filter", "common"]);
    const {filterValues} = useListFilterValues();
    const {timeConverter} = useTimeConverter();
    const { getCouponCodeSearchWordConverter, getBookTypeConverter, getBookStatusConverter}
        = useCouponCodeConverter();

    const searchWordTypeFilter = useMemo(
        () => filterValues.searchWordTypeFilterValue,
        [filterValues.searchWordTypeFilterValue]
    );
    const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
    const createdFromFilter = useMemo(
        () => filterValues.createdFromFilterValue,
        [filterValues.createdFromFilterValue]
    );
    const createdToFilter = useMemo(
        () => filterValues.createdToFilterValue,
        [filterValues.createdToFilterValue]
    );
    const availableStartAtFilter = useMemo(
        () => filterValues.availableStartAtFilterValue,
        [filterValues.availableStartAtFilterValue]
    );
    const availableEndAtFilter = useMemo(
        () => filterValues.availableEndAtFilterValue,
        [filterValues.availableEndAtFilterValue]
    );
    const bookTypeFilter = useMemo(
        () => filterValues.bookTypeFilterValue,
        [filterValues.bookTypeFilterValue]
    );
    const bookStatusFilter = useMemo(
        () => filterValues.bookStatusFilterValue,
        [filterValues.bookStatusFilterValue]);

    const filters: TableFilter[][] = [
        [
            {
                labelTitle: "검색어",
                content: {
                    type: FilterContentType.TEXT,
                    value: searchWordFilter?.value,
                    maxLength: 100,
                    placeholderVal: t("searchWord.placeholder"),
                    onChangeFunc: (v) => searchWordFilter.setter(v),
                    onEnterKeyUpFunc: search,
                    typeSelector: {
                        type: FilterContentType.SELECT,
                        value: searchWordTypeFilter?.value,
                        options: [
                            {label: "전체", value: ""},
                            {
                                value: CouponCodeFilterWordType.NAME,
                                label: getCouponCodeSearchWordConverter(CouponCodeFilterWordType.NAME),
                            },
                        ],
                        onChangeFunc: (v) => searchWordTypeFilter?.setter(v as ExternalVoucherFilterWordType),
                    },
                },
            },
        ],
        [
            {
                labelTitle: "등록일",
                content: {
                    type: FilterContentType.CALENDAR_RANGE,
                    from: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(createdFromFilter?.value),
                        onChangeFunc: (v) =>
                            createdFromFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                    to: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(createdToFilter?.value),
                        onChangeFunc: (v) => createdToFilter?.setter(
                            v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                },
            },
            {
                labelTitle: "사용기한",
                content: {
                    type: FilterContentType.CALENDAR_RANGE,
                    from: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(availableStartAtFilter?.value),
                        onChangeFunc: (v) =>
                            availableStartAtFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                    to: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(availableEndAtFilter?.value),
                        onChangeFunc: (v) => availableEndAtFilter?.setter(
                            v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
                        placeholderVal: t("inputDatePlaceholder"),
                    },
                },
            },
        ],
        [
            {
                labelTitle: "쿠폰 코드 유형",
                content: {
                    type: FilterContentType.SELECT,
                    value: bookTypeFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: CouponCodeBookType.PRICE_DISCOUNT.toString(),
                            label: getBookTypeConverter(CouponCodeBookType.PRICE_DISCOUNT),
                        },
                        {
                            value: CouponCodeBookType.PRODUCT_DISCOUNT.toString(),
                            label: getBookTypeConverter(CouponCodeBookType.PRODUCT_DISCOUNT),
                        },
                    ],
                    onChangeFunc: (v) => bookTypeFilter.setter(v as unknown as CouponCodeBookType),
                },
            },
            {
                labelTitle: "상태",
                content: {
                    type: FilterContentType.SELECT,
                    value: bookStatusFilter.value,
                    options: [
                        {label: "전체", value: ""},
                        {
                            value: CouponCodeBookStatus.PENDING.toString(),
                            label: getBookStatusConverter(CouponCodeBookStatus.PENDING),
                        },
                        {
                            value: CouponCodeBookStatus.USABLE.toString(),
                            label: getBookStatusConverter(CouponCodeBookStatus.USABLE),
                        },
                        {
                            value: CouponCodeBookStatus.EXPIRED.toString(),
                            label: getBookStatusConverter(CouponCodeBookStatus.EXPIRED),
                        },
                        {
                            value: CouponCodeBookStatus.DISABLED.toString(),
                            label: getBookStatusConverter(CouponCodeBookStatus.DISABLED),
                        },
                    ],
                    onChangeFunc: (v) => bookStatusFilter.setter(v as unknown as CouponCodeBookStatus),
                },
            },
        ],
    ];

    return {
        filters
    }
}