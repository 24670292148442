import {MainApi} from "./MainApi";
import {
    CouponCodeDeleteDTO,
    CouponCodeListSearchCondition,
    CouponCodePostDTO,
    CouponCodePutDTO
} from "../types/couponcode";

export class CouponCodeAPI {
    static baseUrl = `${MainApi.urlPrefix}/coupon-code`;

    // 쿠폰코드 리스트 조회
    static getCouponCodeList = (condition: CouponCodeListSearchCondition) => () =>
        MainApi.api.get(`${CouponCodeAPI.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

    // 쿠폰코드 생성
    static generateCouponCodes = (dto: CouponCodePostDTO) => () =>
        MainApi.api.post(`${CouponCodeAPI.baseUrl}`,dto);

    // 쿠폰코드 상세 조회
    static getCouponCode = (idx: string) => () =>
        MainApi.api.get(`${CouponCodeAPI.baseUrl}/${idx}`);

    // 쿠폰코드 상세 수정
    static modifyCouponCode = (dto: CouponCodePutDTO) => () =>
        MainApi.api.put(`${CouponCodeAPI.baseUrl}`,dto);

    // 쿠폰코드 사용 상태 변경
    static updateCouponCodeStatus = (idx: number) => () =>
        MainApi.api.put(`${CouponCodeAPI.baseUrl}/${idx}/status`);

    // 쿠폰코드 삭제
    static removeCouponCodeStatus = (dto: CouponCodeDeleteDTO) => () =>
        MainApi.api.put(`${CouponCodeAPI.baseUrl}/remove`, dto);

    // 쿠폰코드 엑셀 다운로드
    static getCouponCodeExcelDownload = (idx: number) => () =>
        MainApi.api.get(`${CouponCodeAPI.baseUrl}/excel/${idx}`,
            {
                responseType: "blob",
            }
        );
}