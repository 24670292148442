import {useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {TableColumnData} from "../../../types/common";
import {useMemo} from "react";
import {numberWithCommas} from "../../../util/etcUtil";
import {useCouponCodeConverter} from "./useCouponCodeConverter";
import {CouponCodeBookDTO, CouponCodeBookStatus} from "../../../types/couponcode";
import listStyle from "../../../layout/content/list/list.module.scss";
import {CouponCodeListButton} from "../list/CouponCodeListButton";

interface Args {
    refreshList: () => void;
}

export function useCouponCodeColumns({refreshList}: Args) {
    const {timeConverter} = useTimeConverter();
    const {openNewWindowWithURL} = useNewWindow();
    const {getBookStatusConverter} = useCouponCodeConverter();

    const handleCouponCodeModify = (bookIdx: number) => {
        openNewWindowWithURL(`/detail/coupon-code/modify/${bookIdx}`, {
            width: 1000,
            height: 800,
        });
    }

    const handleCouponCodeDetail = (bookIdx: number) => {
        openNewWindowWithURL(`/detail/coupon-code/${bookIdx}`, {
            width: 1000,
            height: 800,
        });
    }
    //
    const statusStyle = (status: CouponCodeBookStatus) =>{
        switch (status) {
            case CouponCodeBookStatus.USABLE:
                return listStyle.blue;
            case CouponCodeBookStatus.DISABLED:
                return listStyle.deleted;
            default:
                return ""
        }
    }

    const createAtColumn: TableColumnData<CouponCodeBookDTO> = useMemo(
        () => ({
            accessor: "createAt",
            header: "등록일",
            sortable: false,
            getNode: (vo: CouponCodeBookDTO) => <span>{timeConverter.convertToLocalTime(vo.createAt)}</span>,
        }), [timeConverter]);

    const eventNameColumn: TableColumnData<CouponCodeBookDTO> = useMemo(
        () => ({
            accessor: "eventName",
            header: "쿠폰 코드 명",
            sortable: false,
            getNode: (vo: CouponCodeBookDTO) =>
                <span className={listStyle.underline} onClick={() => handleCouponCodeModify(vo.idx)}>
                    {vo.eventName}
                </span>,
            //eslint-disable-next-line
        }), []);

    const generateAmountColumn: TableColumnData<CouponCodeBookDTO> = useMemo(
        () => ({
            accessor: "generateAmount",
            header: "생성 개수",
            sortable: false,
            getNode: (vo: CouponCodeBookDTO) => (<>{numberWithCommas(vo.generateAmount)}</>),
            //eslint-disable-next-line
        }), []);

    const usedCountColumn: TableColumnData<CouponCodeBookDTO> = useMemo(
        () => ({
            accessor: "usedCount",
            header: "사용개수",
            sortable: false,
            getNode: (vo: CouponCodeBookDTO) => (<>{numberWithCommas(vo.usedCount)}</>),
            // width: 130
            //eslint-disable-next-line
        }), []);

    const availableDateColumn: TableColumnData<CouponCodeBookDTO> = useMemo(
        () => ({
            accessor: "availableStartAt",
            header: "사용 기한",
            sortable: false,
            getNode: (vo: CouponCodeBookDTO) =>
                //eslint-disable-next-line
                <span className={listStyle.underline} onClick={() => handleCouponCodeModify(vo.idx)}>
                    {`${timeConverter.convertToLocalTime(vo.availableStartAt)}
~${timeConverter.convertToLocalTime(vo.availableEndAt)}`}</span>,
            //eslint-disable-next-line
        }), [timeConverter]);


    const statusColumn: TableColumnData<CouponCodeBookDTO> = useMemo(
        () => ({
            accessor: "status",
            header: "상태",
            sortable: false,
            getNode: (vo: CouponCodeBookDTO) => (
                <span className={statusStyle(vo.status)}>{getBookStatusConverter(vo.status)}</span>
            ),
            //eslint-disable-next-line
        }), []);

    const etcColumn: TableColumnData<CouponCodeBookDTO> = useMemo(
        () => ({
            accessor: "idx",
            header: "관련기능",
            sortable: false,
            getNode: (vo: CouponCodeBookDTO) =>
                <CouponCodeListButton data={vo} refreshList={refreshList}
                                      onClickFunction={handleCouponCodeDetail}
                />,
            //eslint-disable-next-line
        }), []);


    const columns: TableColumnData<CouponCodeBookDTO>[] = useMemo(
        () => [
            createAtColumn,
            eventNameColumn,
            generateAmountColumn,
            usedCountColumn,
            availableDateColumn,
            statusColumn,
            etcColumn,
        ],
        [
            createAtColumn,
            eventNameColumn,
            generateAmountColumn,
            usedCountColumn,
            availableDateColumn,
            statusColumn,
            etcColumn,
        ]
    );

    return {
        columns
    }
}