import style from "../../style/grantModal.module.scss";
import {Label104x46} from "../../../components/Label";
import React from "react";
import {useLanguage} from "../../../hooks/hooks";
import {inputCellphoneNumber} from "../../../util/etcUtil";

interface Args {
    title: string;
    placeholder: string;
    value: string;
    setValue: (_: string) => void
}

export function LabelAndPhoneNumberInputField(
    {title, placeholder, value, setValue}: Args
) {

    const {isKorean} = useLanguage();
    return (
        <div className={style.textInputWrapper}>
            <Label104x46
                text={title}
                className={`${style.informationModalLabel} ${isKorean ? "" : style.notKO}`}/>
            <input
                className={style.inputArea}
                value={value}
                onChange={(e) => inputCellphoneNumber(e, setValue)}
                maxLength={11}
                placeholder={placeholder}
                type="text"
            />
        </div>
    );
}