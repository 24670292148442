import {Table} from "../../../layout/content/list/table/Table";
import {useCouponCodeDetailProductColumns} from "../hooks/useCouponCodeDetailProductColumns";
import {ProductListObj} from "../../../types/product";
import {useUtil} from "../../../util/hooks/useUtil";

interface Args{
    productResult: ProductListObj;
}
export function DetailProductInfoField({productResult}:Args) {

    const { columns } = useCouponCodeDetailProductColumns();
    const { getDefaultPageResponseObject } = useUtil(); // 페이징이 아니지만 페이징으로 처리

    return(
        <>
            <Table
                listData={getDefaultPageResponseObject([productResult])}
                columns={columns}
                getList={() => () => {}}
                noDataText={"데이터가 조회되지 않습니다. 다시 시도해주세요."}
            />
        </>
    )
}